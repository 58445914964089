// BellIcon.js
import React, { useState } from 'react';
import { FaBell } from 'react-icons/fa'; // Import the bell icon
import NotificationList from './NotificationList';
import { useNotification } from './NotificationProvider';

const BellIcon = () => {
    const { notifications, notify } = useNotification(); // Use the custom hook // Access the notification hook
  const [isOpen, setIsOpen] = useState(false);
  
  const toggleSidebar = () => {
    setIsOpen((prev) => !prev);
  };

console.log(notifications,'notificationsBellIcon')

  const unreadCount = notifications ? 1 : 0; // Example logic, adjust as needed

  return (
    <>
      <div onClick={toggleSidebar} style={bellIconStyle}>
        <FaBell style = {{fill : '#ffffff'}}/>
        {unreadCount > 0 && <span style={badgeStyle}>{unreadCount}</span>}
      </div>
      {isOpen && <NotificationList notifications={notifications} onClose={() => setIsOpen(false)} />}
    </>
  );
};

const bellIconStyle = {
  cursor: 'pointer',
  fontSize: '24px',
  position: 'relative', 
};

const badgeStyle = {
  position: 'absolute',
  display : 'flex',
  justifyContent : 'center',
  alignItems : 'center',
  top: '-5px',
  right: '-10px',
  backgroundColor: 'red',
  color: 'white',
  borderRadius: '50%',
  padding: '5px',
  fontSize: '10px',
  fontWeight: 'bold',
  height : '20px',
  width : '20px'
};

export default BellIcon;
