import React, { useState, useEffect } from "react";
import TreeMap from "../../components/TreeMap";
import DoughnutChart from "../../components/DoughnutChart";
import "../../styles/monitorAnalytics.scss";
import axios from "axios";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { BiReset } from "react-icons/bi";
import Autocomplete from "@mui/material/Autocomplete";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import moment from "moment";
import StackBarChart from "../../components/StackBarChart";
import DynamicDataTable from "../../components/DynamicDataTable";
import CustomMultiselect from "../../components/CustomMultiselect";
import LogUserActivity from "../../components/LogUserActivity";
import StaticDownload from "../../components/StaticDownload";

const SecondaryDisplay = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [alertError, setAlertError] = useState(null);
 const [logId, setLogId] = useState(null);
  /* FILTER VARIABLES */
  // FILTER DROPDOWN  OPTIONS
  const [cityFilters, setCityFilters] = useState([]);
  const [citySelectedAll, setCitySelectedAll] = useState(false);
  const [citiesToShow, setCitiesToShow] = useState([]);

  const [customerFilters, setCustomerFilters] = useState([]);
  const [customerSelectedAll, setCustomerSelectedAll] = useState(false);
  const [customersToShow, setCustomersToShow] = useState([]);

  const [categoryFilters, setCategoryFilters] = useState([]);
  const [categorySelectedAll, setCategorySelectedAll] = useState(false);
  const [categoriesToShow, setCategoriesToShow] = useState([]);

  const [isFilterable, setIsFilterable] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  /* SELECTED FILTER VARIABLES */
  const [selectedCategory, setSelectedCategory] = useState("");
  const [tempSelectedStore, setTempSelectedStore] = useState("");
  const [tempInputStore, setTempInputStore] = useState("");
  const [selectedStore, setSelectedStore] = useState("");
  const [tempSelectedStartDate, setTempSelectedStartDate] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [tempSelectedEndDate, setTempSelectedEndDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);

  // DATA VARIABLES
  const [treeMapData, setTreeMapData] = useState(null);
  const [cloroxWithOtherDonughtData, setCloroxWithOtherDonughtData] =
    useState(null);
  const [secondaryDisplayByCity, setSecondaryDisplayByCity] = useState(null);

  // TABLE VARIABLES
  const [tableLoading, setTableLoading] = useState(false);
  const [columnDetails, setColumnDetails] = useState();
  const [rows, setRows] = useState([]);
  const [columnHeaders, setColumnHeaders] = useState([]);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(50);
  const [rowCount, setRowCount] = React.useState();

  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 992px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(max-width: 992px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  const getFilterValues = async () => {
    setIsLoading(true);
    await axios({
      method: "post",
      url: "/api/filter_values_on_shelf",
    })
      .then((response) => {
        if (response?.request?.status === 200) {
          setCategoryFilters(response?.data?.category);
          setCustomerFilters(response?.data?.customer);
          setCityFilters(response?.data?.city);
          console.log(response, "filter values");
        }
      })
      .catch((error) => {
        console.log("Filter Arrays Api: ", error);
        setIsLoading(false);
        setAlertError("There is a problem in the server. Please contact site admin.");
      });
  };
  useEffect(() => {
    const tempColumnDetails = {};
    const tempWaveColumnDetails = {};
    const tempLwcColumnDetails = {};
    if (columnHeaders) {
      columnHeaders
        .filter(function (column) {
          if (column === "ID") {
            return false; // skip
          }
          return true;
        })
        .map((header) => {
          if (header === "Brand") {
            tempColumnDetails[header] = { type: "string", flex: 1 };
          } else {
            tempColumnDetails[header] = { type: "number", flex: 1 };
          }
        });
      setColumnDetails(tempColumnDetails);
    }
  }, [columnHeaders]);
  useEffect(() => {
    fetchTableData();
  }, [page, pageSize]);
  useEffect(() => {
    // const fetchFilters = async () => {
    //   await axios({
    //     method: "post",
    //     url: "/api/Monitor_filter_values",
    //     data: {
    //       username: "",
    //       category: "",
    //       "start date": tempSelectedStartDate
    //         ? moment(tempSelectedStartDate?.$d).format("YYYY-MM-DD")
    //         : "",
    //       "end date": tempSelectedEndDate
    //         ? moment(tempSelectedEndDate?.$d).format("YYYY-MM-DD")
    //         : "",
    //     },
    //   })
    //     .then((response) => {
    //       setIsLoading(false);

    //       if (response.status === 200) {
    //         const responseData = response.data;
    //         setStoreFilters(responseData.store);
    //       }
    //     })
    //     .catch((error) => {
    //       console.log("Filter Arrays Api: ", error);
    //       setIsLoading(false);
    //       setAlertError("There is a problem in the server. Please contact site admin.");
    //     });
    // };
    // if (tempSelectedStartDate) {
    //   if (tempSelectedStartDate && tempSelectedEndDate) {
    //     fetchFilters();
    //   }
    // } else {
    //   fetchFilters();
    // }
    LogUserActivity("Secondary Display Activity Started", props?.currentUser?.user, setLogId, setAlertError);
    getFilterValues();
    fetchTreeMapData();
    fetchCloroxWithOtherDonughtData();
    fetchSecondaryDisplayByCity();
  }, []);

  const fetchTableData = async () => {
    setTableLoading(true);
    await axios({
      method: "post",
      url: "/api/secondary_display_table",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      data: {
        "start date": tempSelectedStartDate
          ? moment(tempSelectedStartDate?.$d).format("YYYY-MM-DD")
          : "",
        "end date": tempSelectedEndDate
          ? moment(tempSelectedEndDate?.$d).format("YYYY-MM-DD")
          : "",
        limit: pageSize,
        skip: page,
        customer: customersToShow,
        category: categoriesToShow,
        city: citiesToShow,
      },
    })
      .then((response) => {
        console.log(response, " table response");
        setTableLoading(false);
        const responseData = response?.data;
        setRows(responseData?.table);
        setRowCount(responseData?.rowCount);
        setColumnHeaders(responseData?.columns);
      })
      .catch((error) => {
        setTableLoading(false);
        setAlertError("something went wrong");
        console.log("Get Filters Api: ", error);
      });
  };

  // useEffect(() => {
  const fetchTreeMapData = async () => {
    await axios({
      method: "post",
      url: "/api/secondary_display_answer",
      data: {
        "start date": tempSelectedStartDate
          ? moment(tempSelectedStartDate?.$d).format("YYYY-MM-DD")
          : "",
        "end date": tempSelectedEndDate
          ? moment(tempSelectedEndDate?.$d).format("YYYY-MM-DD")
          : "",
        customer: customersToShow,
        category: categoriesToShow,
        city: citiesToShow,
      },
    })
      .then((response) => {
        console.log(response, "tree map");
        if (response?.status === 202) {
          setTreeMapData("no data");
        } else {
          setTreeMapData(response?.data);
        }
      })
      .catch((error) => {
        console.log("Get All User Names Api: ", error);
      });
  };
  const fetchCloroxWithOtherDonughtData = async () => {
    await axios({
      method: "post",
      url: "/api/secondary_display_clorox_vs_comp",
      data: {
        "start date": tempSelectedStartDate
          ? moment(tempSelectedStartDate?.$d).format("YYYY-MM-DD")
          : "",
        "end date": tempSelectedEndDate
          ? moment(tempSelectedEndDate?.$d).format("YYYY-MM-DD")
          : "",
        customer: customersToShow,
        category: categoriesToShow,
        city: citiesToShow,
      },
    })
      .then((response) => {
        console.log(response, "donught");
        if (response?.status === 202) {
          setCloroxWithOtherDonughtData("no data");
        } else {
          setCloroxWithOtherDonughtData(response?.data);
        }
      })
      .catch((error) => {
        console.log("Get All User Names Api: ", error);
      });
  };

  const fetchSecondaryDisplayByCity = async () => {
    await axios({
      method: "post",
      url: "/api/secondary_display_city",
      data: {
        "start date": tempSelectedStartDate
          ? moment(tempSelectedStartDate?.$d).format("YYYY-MM-DD")
          : "",
        "end date": tempSelectedEndDate
          ? moment(tempSelectedEndDate?.$d).format("YYYY-MM-DD")
          : "",
        customer: customersToShow,
        category: categoriesToShow,
        city: citiesToShow,
      },
    })
      .then((response) => {
        console.log(response, "stack");
        if (response?.status === 202) {
          setSecondaryDisplayByCity("no data");
        } else {
          setSecondaryDisplayByCity(response?.data);
        }
      })
      .catch((error) => {
        console.log("Get All User Names Api: ", error);
      });
  };
  // }, [selectedStore]);
  const handleSelectStartDate = (newStartDate) => {
    setTempSelectedStartDate(newStartDate);

    if (newStartDate && !tempSelectedEndDate) {
      setStartDateError(false);
      setEndDateError(true);
    } else if (!newStartDate && tempSelectedEndDate) {
      setStartDateError(true);
      setEndDateError(false);
    } else {
      setStartDateError(false);
      setEndDateError(false);
    }
  };

  const handleSelectEndDate = (newEndDate) => {
    setTempSelectedEndDate(newEndDate);
    setIsFilterable(true);
    if (!tempSelectedStartDate && newEndDate) {
      setStartDateError(true);
      setEndDateError(false);
    } else if (tempSelectedStartDate && !newEndDate) {
      setStartDateError(false);
      setEndDateError(true);
    } else {
      setStartDateError(false);
      setEndDateError(false);
    }
  };
  const handleFilter = () => {
    setCloroxWithOtherDonughtData(null);
    setTreeMapData(null);
    setSecondaryDisplayByCity(null);
    setSelectedStore(tempSelectedStore || tempInputStore);
    setSelectedStartDate(
      tempSelectedStartDate
        ? moment(tempSelectedStartDate?.$d).format("YYYY-MM-DD")
        : ""
    );
    setSelectedEndDate(
      tempSelectedEndDate
        ? moment(tempSelectedEndDate?.$d).format("YYYY-MM-DD")
        : ""
    );
    setIsFiltered(true);
    fetchTreeMapData();
    fetchCloroxWithOtherDonughtData();
    fetchSecondaryDisplayByCity();
    fetchTableData();
  };

  const handleReset = () => {
    setSelectedCategory("");
    setTempSelectedStore("");
    setTempInputStore("");
    setSelectedStore("");
    setTempSelectedStartDate("");
    setSelectedStartDate("");
    setTempSelectedEndDate("");
    setSelectedEndDate("");
    setIsFilterable(false);
    setIsFiltered(false);
  };
  const handleStoreChange = (store) => {
    setTempSelectedStore(store);
    setIsFilterable(true);
  };
  return (
    <div className="monitor_performance secondary_display">
      <h2 className="heading_sm_center">Secondary Display</h2>
      <div className="filters filters_right">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Start Date"
            inputFormat="YYYY/MM/DD"
            value={tempSelectedStartDate}
            maxDate={tempSelectedEndDate}
            disableFuture={true}
            onChange={handleSelectStartDate}
            renderInput={(params) => (
              <TextField
                {...params}
                error={startDateError}
                helperText={null}
                size="small"
              />
            )}
          />

          <DatePicker
            label="End Date"
            inputFormat="YYYY/MM/DD"
            value={tempSelectedEndDate}
            minDate={tempSelectedStartDate}
            disableFuture={true}
            onChange={handleSelectEndDate}
            renderInput={(params) => (
              <TextField
                {...params}
                error={endDateError}
                helperText={null}
                size="small"
              />
            )}
          />
        </LocalizationProvider>
        <CustomMultiselect
          filterValues={cityFilters}
          isSelectedAll={citySelectedAll}
          label="City"
          setValuesToShow={setCitiesToShow}
          setSelectedAll={setCitySelectedAll}
          valuesToShow={citiesToShow ? citiesToShow : []}
        />
        <CustomMultiselect
          filterValues={customerFilters}
          isSelectedAll={customerSelectedAll}
          label="Customer"
          setValuesToShow={setCustomersToShow}
          setSelectedAll={setCustomerSelectedAll}
          valuesToShow={customersToShow}
        />
        <CustomMultiselect
          filterValues={categoryFilters}
          isSelectedAll={categorySelectedAll}
          label="Category"
          setValuesToShow={setCategoriesToShow}
          setSelectedAll={setCategorySelectedAll}
          valuesToShow={categoriesToShow}
        />
        <div className="btn-group btn-contained" role="group">
          <button
            className="filterButton btn btn-success"
            color="primary"
            disabled={!isFilterable}
            onClick={handleFilter}
          >
            Filter
          </button>
          <button
            className="resetButton btn btn-light btn-outline"
            disabled={!isFiltered}
            onClick={handleReset}
          >
            <BiReset />
          </button>
        </div>
      </div>
      {!(
        cloroxWithOtherDonughtData &&
        treeMapData &&
        secondaryDisplayByCity
      ) && (
        <div className="col-12 d-flex justify-content-center loader">
          <br />
          <div
            className="spinner-border text-info"
            role="status"
            style={{ zIndex: "2" }}
          ></div>
          <div className="loader-message">Loading...</div>
        </div>
      )}

      <div className="row graph_row">
        <div
          className="col-md-5 graph_column my_graph"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: "100px",
          }}
        >
          <label htmlFor="" className="table_label">
            Clorox Vs Other
          </label>
          {cloroxWithOtherDonughtData &&
          cloroxWithOtherDonughtData !== "no data" ? (
            <DoughnutChart
              data={
                cloroxWithOtherDonughtData?.data
                  ? cloroxWithOtherDonughtData?.data
                  : []
              }
              labels={cloroxWithOtherDonughtData?.labels}
              tite={cloroxWithOtherDonughtData?.title}
            />
          ) : (
            <h4 className="text-center">No Data!</h4>
          )}
        </div>
        <div className="col-md-5 graph_column my_graph">
          <label htmlFor="" className="table_label">
            By City
          </label>
          {secondaryDisplayByCity && secondaryDisplayByCity !== "no data" ? (
            <StackBarChart
              dataForChar={
                secondaryDisplayByCity?.data ? secondaryDisplayByCity?.data : []
              }
              chartLabels={secondaryDisplayByCity?.master}
              colors={secondaryDisplayByCity?.color}
            />
          ) : (
            <h4 className="text-center">No Data!</h4>
          )}
        </div>
        <div className="col-12 graph_column my_graph d-flex flex-column justify-content-end">
          <label htmlFor="" className="table_label">
            By Display Type
          </label>
          {treeMapData && treeMapData !== "no data" ? (
            <TreeMap chartData={treeMapData} />
          ) : (
            <h4 className="text-center">No Data!</h4>
          )}
        </div>

        <div className="col-12 graph_column my_graph">
          <div className="position-relative">
          <label htmlFor="" className="table_label">
            Summary Brand By Display
          </label>
          <div className="position-absolute bottom-0 right-0">
            <StaticDownload
              rows={rows} 
              columnHeaders={columnHeaders} 
              columnDetails={columnDetails} 
              fileName="Summary_Brand_By_Display" 
            />
          </div>
          </div>
              {rows?.length > 0 ? (
            <DynamicDataTable
              columnDetails={columnDetails}
              rowID={"ID"}
              columnHeaders={columnHeaders}
              rows={rows}
              isLoading={tableLoading}
              setPageSize={setPageSize}
              pageSize={pageSize}
              setPage={setPage}
              page={page}
              rowCount={rowCount}
            />
          ) : (
            <h4 className="text-center">No Data!</h4>
          )}
        </div>
        <section className="card">
          {/* </div>
                </div> */}
        </section>
        {/* <div className="col-5 graph_column my_graph d-flex flex-column justify-content-end">
          <h5 className="custom_card_heading">By City</h5>
          <StackBarChart
            chartFor="By City"
            dataForChar={secondaryDisplayByCity?.data}
            chartLabels={secondaryDisplayByCity?.master}
            colors={secondaryDisplayByCity?.color}
          />
        </div> */}
      </div>
    </div>
  );
};

export default SecondaryDisplay;
