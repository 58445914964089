import React, { useState, useEffect } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { BiReset } from "react-icons/bi";
import Autocomplete from "@mui/material/Autocomplete";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import ChartView from "./ChartView";
import axios from "axios";
import moment from "moment";
import { Alert, Tabs, Tab } from "react-bootstrap";
import "../../styles/monitorAnalytics.scss";
import DynamicDataTable from "../../components/DynamicDataTable";
import LogUserActivity from "../../components/LogUserActivity";
import dayjs from "dayjs";

const MonitorPerformance = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [alertError, setAlertError] = useState(null);
  const [logId, setLogId] = useState(null);
  /* TAB VARIABLES */
  const [tabs, setTabs] = useState([]);
  const [isFirstTabSelected, setIsFirstTabSelected] = useState(false);
  const [selectedTab, setSelectedTab] = useState("");

  /* FILTER VARIABLES */
  const [categoryFilters, setCategoryFilters] = useState([]);
  const [storeFilters, setStoreFilters] = useState([]);
  const [isFilterable, setIsFilterable] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  /* SELECTED FILTER VARIABLES */
  const [tempSelectedCategory, setTempSelectedCategory] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [tempSelectedStore, setTempSelectedStore] = useState("");
  const [tempInputStore, setTempInputStore] = useState("");
  const [selectedStore, setSelectedStore] = useState("");
  const [tempSelectedStartDate, setTempSelectedStartDate] = useState(
    dayjs().subtract(1, "month").startOf("month")
  );
  const [tempSelectedEndDate, setTempSelectedEndDate] = useState(
    dayjs().subtract(1, "month").endOf("month")
  );
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);

  /* STATIC VARIABLES */
  const [totalVisits, setTotalVisits] = useState(0);
  const [totalStores, setTotalStores] = useState(0);
  const [averageTimePerVisit, setAverageTimePerVisit] = useState(0);
  const [totalAuditTimePerVisit, setTotalAuditTimePerVisit] = useState(0);

  /* NUMBER OF STORES CHART VARIABLES */
  const [isNumberOfStoresDataAvailable, setIsNumberOfStoresDataAvailable] =
    useState(false);
  const [isNumberOfStoresLoading, setIsNumberOfStoresLoading] = useState(false);
  const [monitors, setMonitors] = useState([]);
  const [numberOfStoresDates, setNumberOfStoresDates] = useState([]);
  const [numberOfStores, setNumberOfStores] = useState([]);

  /* SHARE OF SHELF CHART VARIABLES */
  const [
    isShareOfShelfByMonthDataAvailable,
    setIsShareOfShelfByMonthDataAvailable,
  ] = useState(false);
  const [isShareOfShelfByMonthLoading, setIsShareOfShelfByMonthLoading] =
    useState(false);
  const [months, setMonths] = useState([]);
  const [shareOfShelfValuesByMonth, setShareOfShelfValuesByMonth] = useState(
    []
  );

  /* SECONDARY DISPLAY CHART VARIABLES */
  const [
    isSecondaryDisplayByCategoryDataAvailable,
    setIsSecondaryDisplayByCategoryDataAvailable,
  ] = useState(false);
  const [
    isSecondaryDisplayByCategoryLoading,
    setIsSecondaryDisplayByCategoryLoading,
  ] = useState(false);
  const [categories, setCategories] = useState([]);
  const [
    secondaryDisplayValuesByCategory,
    setSecondaryDisplayValuesByCategory,
  ] = useState([]);
  const [
    isSecondaryDisplayByStoreDataAvailable,
    setIsSecondaryDisplayByStoreDataAvailable,
  ] = useState(false);
  const [
    isSecondaryDisplayByStoreLoading,
    setIsSecondaryDisplayByStoreLoading,
  ] = useState(false);
  const [stores, setStores] = useState([]);
  const [secondaryDisplayValuesByStore, setSecondaryDisplayValuesByStore] =
    useState([]);
  const [
    isSecondaryDisplayByDateDataAvailable,
    setIsSecondaryDisplayByDateDataAvailable,
  ] = useState(false);
  const [isSecondaryDisplayByDateLoading, setIsSecondaryDisplayByDateLoading] =
    useState(false);
  const [secondaryDisplayDates, setSecondaryDisplayDates] = useState([]);
  const [secondaryDisplayValuesByDate, setSecondaryDisplayValuesByDate] =
    useState([]);

  /* TOTAL ACTUAL TIME CHART VARIABLES */
  const [isTotalActualTimeDataAvailable, setIsTotalActualTimeDataAvailable] =
    useState(false);
  const [isTotalActualTimeLoading, setIsTotalActualTimeLoading] =
    useState(false);
  const [totalActualTimeDates, setTotalActualTimeDates] = useState([]);
  const [totalActualTimeByDate, setTotalActualTimeByDate] = useState([]);

  // CSV DOWNLOAD
  const [csvData, setCSVData] = useState();
  const [buttonClicked, setButtonClicked] = useState(false);
  const [sosButtonClicked, setSOSButtonClicked] = useState(false);
  const [monitorDataButtonClicked, setMonitorDataButtonClicked] =
    useState(false);

  // VISITS PER STORE
  const [visitsPerStoreTableLoading, setVisitsPerStoreTableLoading] =
    useState(false);
  const [visitsPerStoreColumnDetails, setVisitsPerStoreColumnDetails] =
    useState();
  const [visitsPerStoreRows, setVisitsPerStoreRows] = useState([]);
  const [visitsPerStoreColumnHeaders, setVisitsPerStoreColumnHeaders] =
    useState([]);
  const [visitsPerStorePage, setVisitsPerStorePage] = React.useState(0);
  const [visitsPerStorePageSize, setVisitsPerStorePageSize] =
    React.useState(25);
  const [visitsPerStoreRowCount, setVisitsPerStoreRowCount] = React.useState();

  const ITEM_HEIGHT = 50;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5,
        width: 180,
      },
    },
  };

  useEffect(() => {
    LogUserActivity(
      "Monitor Performance Activity Started",
      props?.currentUser?.user,
      setLogId,
      setAlertError
    );
  }, []);

  console.log(selectedStartDate, "se;start date");
  useEffect(() => {
    const tempvisitsPerStoreColumnDetails = {};

    if (visitsPerStoreColumnHeaders) {
      visitsPerStoreColumnHeaders
        .filter(function (column) {
          if (column === "ID") {
            return false; // skip
          }
          return true;
        })
        .map((header) => {
          if (header === "storeCount") {
            tempvisitsPerStoreColumnDetails[header] = {
              type: "number",
              flex: 1,
            };
          } else {
            tempvisitsPerStoreColumnDetails[header] = {
              type: "string",
              flex: 1,
            };
          }
        });
      setVisitsPerStoreColumnDetails(tempvisitsPerStoreColumnDetails);
    }
  }, [visitsPerStoreColumnHeaders]);

  // VISITS PER STORE
  useEffect(() => {
    const fetchTableData = async () => {
      setVisitsPerStoreTableLoading(true);
      await axios({
        method: "post",
        url: "/api/storevisit",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          username: selectedTab,
          category: selectedCategory,
          store: selectedStore,
          "start date": selectedStartDate ? selectedStartDate : "",
          "end date": selectedEndDate ? selectedEndDate : "",
          limit: visitsPerStorePageSize,
          skip: visitsPerStorePage,
        }),
      })
        .then((response) => {
          console.log(response, " lowesr without category");
          setVisitsPerStoreTableLoading(false);
          const responseData = response?.data;
          setVisitsPerStoreRows(responseData?.table);
          setVisitsPerStoreRowCount(responseData?.rowCount);
          setVisitsPerStoreColumnHeaders(responseData?.columns);
        })
        .catch((error) => {
          setVisitsPerStoreTableLoading(false);
          setAlertError("something went wrong");
          console.log("Get Filters Api: ", error);
        });
    };
    fetchTableData();
  }, [
    visitsPerStorePage,
    visitsPerStorePageSize,
    selectedTab,
    selectedCategory,
    selectedStore,
    selectedStartDate,
    selectedEndDate,
  ]);

  // const downloadCSV = async (url, name) => {
  //     setCSVData(null);

  //     if (url === '/api/SOS_template') {
  //         setSOSButtonClicked(true);
  //         setMonitorDataButtonClicked(false)
  //         setButtonClicked(false);
  //     }
  //     else if (url === '/api/template') {
  //         setSOSButtonClicked(false);
  //         setMonitorDataButtonClicked(false)
  //         setButtonClicked(true);
  //     }
  //     else if (url === '/api/Monitor_Data') {
  //         setMonitorDataButtonClicked(true)
  //         setSOSButtonClicked(false);
  //         setButtonClicked(false);
  //     }
  //     const getTemplate = async () => {
  //         await axios({
  //             method: "post",
  //             url: url,
  //             responseType: "blob"
  //         })
  //             .then((response) => {
  //                 setCSVData(response.data);

  //                 const blob = new Blob([response.data], { type: "data:text/xlsx;charset=utf-8," });
  //                 const blobURL = window.URL.createObjectURL(blob);

  //                 // Create new tag for download file
  //                 const anchor = document.createElement("a");
  //                 anchor.download = name;
  //                 anchor.href = blobURL;
  //                 anchor.dataset.downloadurl = ["text/xlsx", anchor.download, anchor.href].join(":");
  //                 anchor.click();

  //                 // Remove URL.createObjectURL. The browser should not save the reference to the file.
  //                 setTimeout(() => {
  //                     // For Firefox it is necessary to delay revoking the ObjectURL
  //                     URL.revokeObjectURL(blobURL);
  //                 }, 100);
  //             })
  //             .catch(function (error) {
  //                 console.log(error);
  //                 setMonitorDataButtonClicked(false)
  //                 setSOSButtonClicked(false);
  //                 setButtonClicked(false);
  //             });
  //     }

  //     getTemplate();
  // }

  useEffect(() => {
    const fetchFilters = async () => {
      await axios({
        method: "post",
        url: "/api/Monitor_filter_values",
        data: {
          username: selectedTab,
          category: tempSelectedCategory,
          "start date": tempSelectedStartDate
            ? moment(tempSelectedStartDate?.$d).format("YYYY-MM-DD")
            : "",
          "end date": tempSelectedEndDate
            ? moment(tempSelectedEndDate?.$d).format("YYYY-MM-DD")
            : "",
        },
      })
        .then((response) => {
          setIsLoading(false);

          if (response.status === 200) {
            const responseData = response.data;
            setCategoryFilters(responseData.category);
            setStoreFilters(responseData.store);
          }
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };
    if (categoryFilters.length > 0) {
      if (
        tempSelectedCategory ||
        (tempSelectedStartDate && tempSelectedEndDate) ||
        selectedTab
      ) {
        fetchFilters();
      }
    } else {
      fetchFilters();
    }
  }, [
    tempSelectedCategory,
    tempSelectedStartDate,
    tempSelectedEndDate,
    selectedTab,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setIsNumberOfStoresDataAvailable(true);
      setIsNumberOfStoresLoading(true);
      setAlertError(null);

      if (!selectedTab) {
        setNumberOfStoresDates([]);

        await axios({
          method: "post",
          url: "/api/no_of_stores_data",
          headers: { "Content-Type": "application/json" },
          data: {
            username: "",
            category: selectedCategory,
            store: selectedStore,
            "start date": selectedStartDate ? selectedStartDate : "",
            "end date": selectedEndDate ? selectedEndDate : "",
          },
        })
          .then((response) => {
            setIsNumberOfStoresLoading(false);
            setIsLoading(false);
            if (response.status === 200) {
              setIsNumberOfStoresDataAvailable(true);

              const monitorTabs = Object.keys(response.data);
              const monitors = Object.keys(response.data);
              const numberOfStores = Object.values(response.data);
              let datasets = [];

              monitorTabs.unshift("All Monitors");

              datasets.push({
                label: "Number Of Stores",
                data: numberOfStores,
                backgroundColor:
                  "#" + Math.floor(Math.random() * 16777215).toString(16),
              });

              if (
                !tabs ||
                tabs?.length === 0 ||
                (tabs?.length > 0 && tabs?.length !== monitorTabs.length)
              ) {
                setTabs(monitorTabs);
              }

              setMonitors(monitors);
              setNumberOfStores(datasets);
            } else if (response.status === 202) {
              setIsNumberOfStoresDataAvailable(false);
            }
          })
          .catch((error) => {
            console.log("Number of Stores by User Api: ", error);
            setIsNumberOfStoresLoading(false);
            setAlertError("There is a problem in the server. Please contact site admin.");
          });
      } else {
        setMonitors([]);

        await axios({
          method: "post",
          url: "/api/no_of_Stores_month",
          headers: { "Content-Type": "application/json" },
          data: {
            username: selectedTab,
            category: selectedCategory,
            store: selectedStore,
            "start date": selectedStartDate ? selectedStartDate : "",
            "end date": selectedEndDate ? selectedEndDate : "",
          },
        })
          .then((response) => {
            setIsNumberOfStoresLoading(false);

            if (response.status === 200) {
              const responseData = response.data;
              const uniqueDates = [
                ...new Set(responseData?.map((data) => data.visitDate)),
              ];
              let datasets = [];
              let size = [];

              const numberOfStoresValuesByDate = responseData?.reduce(
                (numberOfStoresValuesByDate, data) => {
                  const date = data.visitDate;

                  if (!numberOfStoresValuesByDate[date]) {
                    numberOfStoresValuesByDate[date] = [];
                  }

                  numberOfStoresValuesByDate[date].push(data);
                  return numberOfStoresValuesByDate;
                },
                {}
              );

              const numberOfStoresValueByDateArray = Object.keys(
                numberOfStoresValuesByDate
              ).map((date) => {
                return {
                  date,
                  data: numberOfStoresValuesByDate[date],
                };
              });

              for (let i = 0; i < numberOfStoresValueByDateArray.length; i++) {
                const numberOfStoresByDate = numberOfStoresValueByDateArray[i];

                for (let j = 0; j < numberOfStoresByDate.data.length; j++) {
                  size.push(numberOfStoresByDate.data[j].size);
                }
              }

              datasets.push({
                label: "Number of Stores",
                data: size,
                backgroundColor:
                  "#" + Math.floor(Math.random() * 16777215).toString(16),
              });

              setNumberOfStoresDates(uniqueDates);
              setNumberOfStores(datasets);
            } else if (response.status === 202) {
              setIsNumberOfStoresDataAvailable(false);
            }
          })
          .catch((error) => {
            console.log("Number of Stores by Month Api: ", error);
            setIsNumberOfStoresLoading(false);
            setAlertError("There is a problem in the server. Please contact site admin.");
          });
      }

      axios({
        method: "post",
        url: "/api/no_of_visits",
        headers: { "Content-Type": "application/json" },
        data: {
          username: selectedTab,
          category: selectedCategory,
          store: selectedStore,
          "start date": selectedStartDate ? selectedStartDate : "",
          "end date": selectedEndDate ? selectedEndDate : "",
        },
      })
        .then((response) => {
          if (response.status === 200) {
            const responseData = response.data;
            setTotalVisits(responseData.total);
          }
        })
        .catch((error) => {
          console.log("Total Visits Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });

      //  TOTAL STORES
      axios({
        method: "post",
        url: "/api/no_of_Stores",
        headers: { "Content-Type": "application/json" },
        data: {
          username: selectedTab,
          category: selectedCategory,
          store: selectedStore,
          "start date": selectedStartDate ? selectedStartDate : "",
          "end date": selectedEndDate ? selectedEndDate : "",
        },
      })
        .then((response) => {
          if (response.status === 200) {
            const responseData = response.data;
            console.log(response, "stores");
            setTotalStores(responseData.total);
          }
        })
        .catch((error) => {
          console.log("Total Visits Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });

      axios({
        method: "post",
        url: "/api/monitorDataAvgtime",
        headers: { "Content-Type": "application/json" },
        data: {
          username: selectedTab,
          category: selectedCategory,
          store: selectedStore,
          "start date": selectedStartDate ? selectedStartDate : "",
          "end date": selectedEndDate ? selectedEndDate : "",
        },
      })
        .then((response) => {
          if (response.status === 200) {
            const responseData = response.data;
            const averageTimePerVisit =
              Math.round(responseData.total * 100) / 100;

            setAverageTimePerVisit(averageTimePerVisit);
          }
        })
        .catch((error) => {
          console.log("Average Time per Visit Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });

      if (selectedTab) {
        axios({
          method: "post",
          url: "/api/monitorDataTotaltime",
          headers: { "Content-Type": "application/json" },
          data: {
            username: selectedTab,
            category: selectedCategory,
            store: selectedStore,
            "start date": selectedStartDate ? selectedStartDate : "",
            "end date": selectedEndDate ? selectedEndDate : "",
          },
        })
          .then((response) => {
            if (response.status === 200) {
              const responseData = response.data;
              setTotalAuditTimePerVisit(responseData.total);
            }
          })
          .catch((error) => {
            console.log("Total Audit Time per Visit Api: ", error);
            setIsLoading(false);
            setAlertError("There is a problem in the server. Please contact site admin.");
          });
      }
    };

    fetchData();

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [
    selectedTab,
    selectedCategory,
    selectedStore,
    selectedStartDate,
    selectedEndDate,
  ]);

  useEffect(() => {
    if (tabs && tabs.length > 0) {
      setIsShareOfShelfByMonthDataAvailable(true);
      setIsShareOfShelfByMonthLoading(true);
      setIsSecondaryDisplayByCategoryDataAvailable(true);
      setIsSecondaryDisplayByCategoryLoading(true);
      setIsSecondaryDisplayByStoreDataAvailable(true);
      setIsSecondaryDisplayByStoreLoading(true);
      setIsSecondaryDisplayByDateDataAvailable(true);
      setIsSecondaryDisplayByDateLoading(true);
      setAlertError(null);

      axios({
        method: "post",
        url: "/api/shareofshelf_view",
        headers: { "Content-Type": "application/json" },
        data: {
          username: selectedTab,
          category: selectedCategory,
          store: selectedStore,
          "start date": selectedStartDate ? selectedStartDate : "",
          "end date": selectedEndDate ? selectedEndDate : "",
        },
      })
        .then((response) => {
          setIsShareOfShelfByMonthLoading(false);

          if (response.status === 200) {
            setIsShareOfShelfByMonthDataAvailable(true);

            const responseData = response.data;
            const uniqueDates = [
              ...new Set(responseData?.map((data) => data.date)),
            ];

            const monthNames = [
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ];
            let months = [];
            let datasets = [];

            for (let i = 0; i < uniqueDates.length; i++) {
              months.push(monthNames[new Date(uniqueDates[i]).getMonth()]);
            }

            const shareOfShelfValuesByMonth = responseData?.reduce(
              (shareOfShelfValuesByMonth, data) => {
                const category = data.categoryShortName;

                if (!shareOfShelfValuesByMonth[category]) {
                  shareOfShelfValuesByMonth[category] = [];
                }

                shareOfShelfValuesByMonth[category].push(data);
                return shareOfShelfValuesByMonth;
              },
              {}
            );

            const shareOfShelfValueByMonthArray = Object.keys(
              shareOfShelfValuesByMonth
            ).map((category) => {
              return {
                category,
                data: shareOfShelfValuesByMonth[category],
              };
            });

            for (let i = 0; i < shareOfShelfValueByMonthArray.length; i++) {
              const shareOfShelfByMonth = shareOfShelfValueByMonthArray[i];
              let average = [];

              for (let j = 0; j < shareOfShelfByMonth.data.length; j++) {
                average.push(shareOfShelfByMonth.data[j].average);
              }

              datasets.push({
                label: shareOfShelfByMonth.category,
                data: average,
                backgroundColor:
                  "#" + Math.floor(Math.random() * 16777215).toString(16),
              });
            }

            setMonths(months);
            setShareOfShelfValuesByMonth(datasets);
          } else if (response.status === 202) {
            setIsShareOfShelfByMonthDataAvailable(false);
          }
        })
        .catch((error) => {
          console.log("Share of Shelf by Month Api: ", error);
          setIsShareOfShelfByMonthLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });

      axios({
        method: "post",
        url: "/api/secondary_display",
        headers: { "Content-Type": "application/json" },
        data: {
          username: selectedTab,
          category: selectedCategory,
          store: selectedStore,
          "start date": selectedStartDate ? selectedStartDate : "",
          "end date": selectedEndDate ? selectedEndDate : "",
        },
      })
        .then((response) => {
          setIsSecondaryDisplayByCategoryLoading(false);

          if (response.status === 200) {
            setIsSecondaryDisplayByCategoryDataAvailable(true);

            const responseData = response.data;
            const uniqueCategories = [
              ...new Set(responseData?.map((data) => data.categoryShortName)),
            ];
            let datasets = [];

            const secondaryDisplayValuesByCategory = responseData?.reduce(
              (secondaryDisplayValuesByCategory, data) => {
                const answer = data.answer;

                if (!secondaryDisplayValuesByCategory[answer]) {
                  secondaryDisplayValuesByCategory[answer] = [];
                }

                secondaryDisplayValuesByCategory[answer].push(data);
                return secondaryDisplayValuesByCategory;
              },
              {}
            );

            const secondaryDisplayValueByCategoryArray = Object.keys(
              secondaryDisplayValuesByCategory
            ).map((answer) => {
              return {
                answer,
                data: secondaryDisplayValuesByCategory[answer],
              };
            });

            for (
              let i = 0;
              i < secondaryDisplayValueByCategoryArray.length;
              i++
            ) {
              const secondaryDisplayByCategory =
                secondaryDisplayValueByCategoryArray[i];
              let size = [];

              for (let j = 0; j < secondaryDisplayByCategory.data.length; j++) {
                size.push(secondaryDisplayByCategory.data[j].size);
              }

              datasets.push({
                label: secondaryDisplayByCategory.answer,
                data: size,
                backgroundColor:
                  "#" + Math.floor(Math.random() * 16777215).toString(16),
              });
            }

            setCategories(uniqueCategories);
            setSecondaryDisplayValuesByCategory(datasets);
          } else if (response.status === 202) {
            setIsSecondaryDisplayByCategoryDataAvailable(false);
          }
        })
        .catch((error) => {
          console.log("Secondary Displays by Category Api: ", error);
          setIsSecondaryDisplayByCategoryLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });

      axios({
        method: "post",
        url: "/api/secondary_display_store",
        headers: { "Content-Type": "application/json" },
        data: {
          username: selectedTab,
          category: selectedCategory,
          store: selectedStore,
          "start date": selectedStartDate ? selectedStartDate : "",
          "end date": selectedEndDate ? selectedEndDate : "",
        },
      })
        .then((response) => {
          setIsSecondaryDisplayByStoreLoading(false);

          if (response.status === 200) {
            setIsSecondaryDisplayByStoreDataAvailable(true);

            const responseData = response.data;
            const uniqueStores = [
              ...new Set(responseData?.map((data) => data.storeName)),
            ];
            let datasets = [];

            const secondaryDisplayValuesByStore = responseData?.reduce(
              (secondaryDisplayValuesByStore, data) => {
                const answer = data.answer;

                if (!secondaryDisplayValuesByStore[answer]) {
                  secondaryDisplayValuesByStore[answer] = [];
                }

                secondaryDisplayValuesByStore[answer].push(data);
                return secondaryDisplayValuesByStore;
              },
              {}
            );

            const secondaryDisplayValueByStoreArray = Object.keys(
              secondaryDisplayValuesByStore
            ).map((answer) => {
              return {
                answer,
                data: secondaryDisplayValuesByStore[answer],
              };
            });

            for (let i = 0; i < secondaryDisplayValueByStoreArray.length; i++) {
              const secondaryDisplayByStore =
                secondaryDisplayValueByStoreArray[i];
              let size = [];

              for (let j = 0; j < secondaryDisplayByStore.data.length; j++) {
                size.push(secondaryDisplayByStore.data[j].size);
              }

              datasets.push({
                label: secondaryDisplayByStore.answer,
                data: size,
                backgroundColor:
                  "#" + Math.floor(Math.random() * 16777215).toString(16),
              });
            }

            setStores(uniqueStores);
            setSecondaryDisplayValuesByStore(datasets);
          } else if (response.status === 202) {
            setIsSecondaryDisplayByStoreDataAvailable(false);
          }
        })
        .catch((error) => {
          console.log("Secondary Displays by Store Api: ", error);
          setIsSecondaryDisplayByStoreLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });

      axios({
        method: "post",
        url: "/api/secondary_display_time",
        headers: { "Content-Type": "application/json" },
        data: {
          username: selectedTab,
          category: selectedCategory,
          store: selectedStore,
          "start date": selectedStartDate ? selectedStartDate : "",
          "end date": selectedEndDate ? selectedEndDate : "",
        },
      })
        .then((response) => {
          setIsSecondaryDisplayByDateLoading(false);

          if (response.status === 200) {
            setIsSecondaryDisplayByDateDataAvailable(true);

            const responseData = response.data;
            const uniqueDates = [
              ...new Set(responseData?.map((data) => data.date)),
            ];
            let datasets = [];

            const secondaryDisplayValuesByDate = responseData?.reduce(
              (secondaryDisplayValuesByDate, data) => {
                const answer = data.answer;

                if (!secondaryDisplayValuesByDate[answer]) {
                  secondaryDisplayValuesByDate[answer] = [];
                }

                secondaryDisplayValuesByDate[answer].push(data);
                return secondaryDisplayValuesByDate;
              },
              {}
            );

            const secondaryDisplayValueByDateArray = Object.keys(
              secondaryDisplayValuesByDate
            ).map((answer) => {
              return {
                answer,
                data: secondaryDisplayValuesByDate[answer],
              };
            });

            for (let i = 0; i < secondaryDisplayValueByDateArray.length; i++) {
              const secondaryDisplayByDate =
                secondaryDisplayValueByDateArray[i];
              let size = [];

              for (let j = 0; j < uniqueDates.length; j++) {
                const found = secondaryDisplayByDate.data.some(
                  (data) => data.date === uniqueDates[j]
                );

                if (found && j < secondaryDisplayByDate.data.length) {
                  size.push(secondaryDisplayByDate.data[j].size);
                } else {
                  size.push(0);
                }
              }

              datasets.push({
                label: secondaryDisplayByDate.answer,
                data: size,
                backgroundColor:
                  "#" + Math.floor(Math.random() * 16777215).toString(16),
              });
            }

            setSecondaryDisplayDates(uniqueDates);
            setSecondaryDisplayValuesByDate(datasets);
          } else if (response.status === 202) {
            setIsSecondaryDisplayByDateDataAvailable(false);
          }
        })
        .catch((error) => {
          console.log("Secondary Displays by Time Api: ", error);
          setIsSecondaryDisplayByDateLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });

      if (selectedTab) {
        setIsTotalActualTimeDataAvailable(true);
        setIsTotalActualTimeLoading(true);

        axios({
          method: "post",
          url: "/api/total_actual_time_per_visit",
          headers: { "Content-Type": "application/json" },
          data: {
            username: selectedTab,
            category: selectedCategory,
            store: selectedStore,
            "start date": selectedStartDate ? selectedStartDate : "",
            "end date": selectedEndDate ? selectedEndDate : "",
          },
        })
          .then((response) => {
            setIsTotalActualTimeLoading(false);

            if (response.status === 200) {
              setIsTotalActualTimeDataAvailable(true);

              const responseData = response.data;
              const timeData = Object.keys(responseData)
                .map((key) => {
                  responseData[key].forEach((i) => {
                    i.timeType = key;
                  });

                  return responseData[key];
                })
                .flat();

              const uniqueDates = [
                ...new Set(timeData?.map((time) => time.visitDate)),
              ];
              let datasets = [];

              const totalActualTimeValues = timeData?.reduce(
                (totalActualTimeValues, data) => {
                  const timeType = data.timeType;

                  if (!totalActualTimeValues[timeType]) {
                    totalActualTimeValues[timeType] = [];
                  }

                  totalActualTimeValues[timeType].push(data);
                  return totalActualTimeValues;
                },
                {}
              );

              const totalActualTimeValueArray = Object.keys(
                totalActualTimeValues
              ).map((timeType) => {
                return {
                  timeType,
                  data: totalActualTimeValues[timeType],
                };
              });

              for (let i = 0; i < totalActualTimeValueArray.length; i++) {
                const totalActualTime = totalActualTimeValueArray[i];
                let timeValue = [];

                for (let j = 0; j < totalActualTime.data.length; j++) {
                  timeValue.push(totalActualTime.data[j].time_val);
                }

                datasets.push({
                  label: totalActualTime.timeType,
                  data: timeValue,
                  backgroundColor:
                    "#" + Math.floor(Math.random() * 16777215).toString(16),
                });
              }

              setTotalActualTimeDates(uniqueDates);
              setTotalActualTimeByDate(datasets);
            } else if (response.status === 202) {
              setIsTotalActualTimeDataAvailable(false);
            }
          })
          .catch((error) => {
            console.log("Total Actual Time by Visit Api: ", error);
            setIsTotalActualTimeLoading(false);
            setAlertError("There is a problem in the server. Please contact site admin.");
          });
      }
    }
  }, [
    tabs,
    selectedTab,
    selectedCategory,
    selectedStore,
    selectedStartDate,
    selectedEndDate,
  ]);

  useEffect(() => {
    if (
      (tempSelectedCategory ||
        tempSelectedStore ||
        (tempSelectedStartDate && tempSelectedEndDate)) &&
      !startDateError &&
      !endDateError
    ) {
      setIsFilterable(true);
    } else {
      setIsFilterable(false);
    }
  }, [
    tempSelectedCategory,
    tempSelectedStore,
    tempSelectedStartDate,
    tempSelectedEndDate,
    startDateError,
    endDateError,
  ]);

  const handleSelectTab = (eventKey) => {
    if (parseInt(eventKey) === 0) {
      setSelectedTab("");
      setTempSelectedCategory("");
      setSelectedCategory("");
      setTempSelectedStore("");
      setTempInputStore("");
      setSelectedStore("");
      setTempSelectedStartDate(null);
      setSelectedStartDate("");
      setTempSelectedEndDate(null);
      setSelectedEndDate("");
      setIsFilterable(false);
      setIsFiltered(false);
    } else {
      setSelectedTab(tabs[eventKey]);
      setTempSelectedCategory("");
      setSelectedCategory("");
      setTempSelectedStore("");
      setTempInputStore("");
      setSelectedStore("");
      setTempSelectedStartDate(null);
      setSelectedStartDate("");
      setTempSelectedEndDate(null);
      setSelectedEndDate("");
      setIsFilterable(false);
      setIsFiltered(false);
    }
  };

  const handleChange = (event) => {
    const {
      target: { name, value },
    } = event;

    if (name === "category") {
      setTempSelectedCategory(value);
    }
  };

  const handleSelectStartDate = (newStartDate) => {
    setTempSelectedStartDate(newStartDate);

    if (newStartDate && !tempSelectedEndDate) {
      setStartDateError(false);
      setEndDateError(true);
    } else if (!newStartDate && tempSelectedEndDate) {
      setStartDateError(true);
      setEndDateError(false);
    } else {
      setStartDateError(false);
      setEndDateError(false);
    }
  };

  const handleSelectEndDate = (newEndDate) => {
    setTempSelectedEndDate(newEndDate);

    if (!tempSelectedStartDate && newEndDate) {
      setStartDateError(true);
      setEndDateError(false);
    } else if (tempSelectedStartDate && !newEndDate) {
      setStartDateError(false);
      setEndDateError(true);
    } else {
      setStartDateError(false);
      setEndDateError(false);
    }
  };

  const handleFilter = () => {
    setSelectedCategory(tempSelectedCategory);
    setSelectedStore(tempSelectedStore || tempInputStore);
    setSelectedStartDate(
      tempSelectedStartDate
        ? moment(tempSelectedStartDate?.$d).format("YYYY-MM-DD")
        : ""
    );
    setSelectedEndDate(
      tempSelectedEndDate
        ? moment(tempSelectedEndDate?.$d).format("YYYY-MM-DD")
        : ""
    );
    setIsFiltered(true);
  };

  const handleReset = () => {
    const defaultStartDate = dayjs().subtract(1, "month").startOf("month").format("YYYY/MM/DD");
    const defaultEndDate = dayjs().subtract(1, "month").endOf("month").format("YYYY/MM/DD");
    setTempSelectedCategory("");
    setSelectedCategory("");
    setTempSelectedStore("");
    setTempInputStore("");
    setSelectedStore("");
    setTempSelectedStartDate(defaultStartDate);
    setSelectedStartDate("");
    setTempSelectedEndDate(defaultEndDate);
    setSelectedEndDate("");
    setIsFilterable(false);
    setIsFiltered(false);
  };

  return (
    <div className="container-fluid monitor_performance position-relative">
      <h2 className="heading_sm_center">Monitor Performance</h2>
      {isLoading && !selectedTab && !isFirstTabSelected ? (
        <div className="col-12 d-flex justify-content-center loader">
          <br />
          <div
            className="spinner-border text-info"
            role="status"
            style={{ zIndex: "2" }}
          ></div>
          <div className="loader-message">Loading...</div>
        </div>
      ) : (
        <>
          {alertError && <Alert variant="danger">{alertError}</Alert>}

          {tabs && tabs.length > 0 && (
            <Tabs
              defaultActiveKey={0}
              className="mb-3"
              justify={true}
              onSelect={handleSelectTab}
            >
              {tabs.map((tab, index) => (
                <Tab key={index} eventKey={index} title={tab}>
                  <div className="filters filters_right">
                    <FormControl sx={{ width: 200 }} size="small">
                      <InputLabel id="category" style={{ fontSize: "14px" }}>
                        Category
                      </InputLabel>
                      <Select
                        labelId="category"
                        multiple={false}
                        name="category"
                        value={tempSelectedCategory}
                        onChange={handleChange}
                        input={<OutlinedInput label="Category" />}
                        renderValue={() => tempSelectedCategory}
                        MenuProps={MenuProps}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {categoryFilters.map((category) => (
                          <MenuItem key={category} value={category}>
                            {category}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl sx={{ width: 200 }} size="small">
                      {/* <InputLabel id = "store" style = {{ fontSize: '14px' }}>Store</InputLabel>
                                            <Select
                                                labelId = "store"
                                                name = "store"
                                                multiple = {false}
                                                value = {tempSelectedStore}
                                                onChange = {handleChange}
                                                input = {<OutlinedInput label = "Store" />}
                                                renderValue = {() => tempSelectedStore}
                                                MenuProps = {MenuProps}
                                            >
                                                <MenuItem value = ""><em>None</em></MenuItem>
                                                {storeFilters.map((store) => (
                                                    <MenuItem key = {store} value = {store}>{store}</MenuItem>
                                                ))}
                                            </Select> */}

                      <Autocomplete
                        name="store"
                        freeSolo={true}
                        options={storeFilters}
                        value={tempSelectedStore}
                        onChange={(event, selectedValue) => {
                          setTempSelectedStore(selectedValue);
                        }}
                        inputValue={tempInputStore}
                        onInputChange={(event, inputValue) => {
                          setTempInputStore(inputValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Store" size="small" />
                        )}
                      />
                    </FormControl>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Start Date"
                        inputFormat="YYYY/MM/DD"
                        value={tempSelectedStartDate}
                        maxDate={tempSelectedEndDate}
                        disableFuture={true}
                        onChange={handleSelectStartDate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={startDateError}
                            helperText={null}
                            size="small"
                          />
                        )}
                      />

                      <DatePicker
                        label="End Date"
                        inputFormat="YYYY/MM/DD"
                        value={tempSelectedEndDate}
                        minDate={tempSelectedStartDate}
                        disableFuture={true}
                        onChange={handleSelectEndDate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={endDateError}
                            helperText={null}
                            size="small"
                          />
                        )}
                      />
                    </LocalizationProvider>
                    <div className="btn-group btn-contained" role="group">
                      <button
                        className="filterButton btn btn-success"
                        color="primary"
                        disabled={!isFilterable}
                        onClick={handleFilter}
                      >
                        Filter
                      </button>
                      <button
                        className="resetButton btn btn-light btn-outline"
                        disabled={!isFiltered}
                        onClick={handleReset}
                      >
                        <BiReset />
                      </button>
                    </div>
                  </div>
                  <br />

                  {isLoading && selectedTab && isFirstTabSelected ? (
                    <div className="col-12 d-flex justify-content-center loader">
                      <br />
                      <div
                        className="spinner-border text-info"
                        role="status"
                        style={{ zIndex: "2" }}
                      ></div>
                      <div className="loader-message">Loading...</div>
                    </div>
                  ) : (
                    <>
                      <ChartView
                        tab={tab}
                        /*------------------------------------------------*/
                        columnDetails={visitsPerStoreColumnDetails}
                        rowID={"ID"}
                        columnHeaders={visitsPerStoreColumnHeaders}
                        rows={visitsPerStoreRows}
                        isLoading={visitsPerStoreTableLoading}
                        setPageSize={setVisitsPerStorePageSize}
                        pageSize={visitsPerStorePageSize}
                        setPage={setVisitsPerStorePage}
                        page={visitsPerStorePage}
                        rowCount={visitsPerStoreRowCount}
                        /*------------------------------------------------*/
                        totalVisits={totalVisits}
                        totalStores={totalStores}
                        averageTimePerVisit={averageTimePerVisit}
                        totalAuditTimePerVisit={totalAuditTimePerVisit}
                        /*------------------------------------------------*/
                        isNumberOfStoresDataAvailable={
                          isNumberOfStoresDataAvailable
                        }
                        isNumberOfStoresLoading={isNumberOfStoresLoading}
                        monitors={monitors}
                        numberOfStoresDates={numberOfStoresDates}
                        numberOfStores={numberOfStores}
                        /*------------------------------------------------*/
                        isShareOfShelfByMonthDataAvailable={
                          isShareOfShelfByMonthDataAvailable
                        }
                        isShareOfShelfByMonthLoading={
                          isShareOfShelfByMonthLoading
                        }
                        months={months}
                        shareOfShelfValuesByMonth={shareOfShelfValuesByMonth}
                        /*------------------------------------------------*/
                        isSecondaryDisplayByCategoryDataAvailable={
                          isSecondaryDisplayByCategoryDataAvailable
                        }
                        isSecondaryDisplayByCategoryLoading={
                          isSecondaryDisplayByCategoryLoading
                        }
                        categories={categories}
                        secondaryDisplayValuesByCategory={
                          secondaryDisplayValuesByCategory
                        }
                        /*------------------------------------------------*/
                        isSecondaryDisplayByStoreDataAvailable={
                          isSecondaryDisplayByStoreDataAvailable
                        }
                        isSecondaryDisplayByStoreLoading={
                          isSecondaryDisplayByStoreLoading
                        }
                        stores={stores}
                        secondaryDisplayValuesByStore={
                          secondaryDisplayValuesByStore
                        }
                        /*------------------------------------------------*/
                        isSecondaryDisplayByDateDataAvailable={
                          isSecondaryDisplayByDateDataAvailable
                        }
                        isSecondaryDisplayByDateLoading={
                          isSecondaryDisplayByDateLoading
                        }
                        secondaryDisplayDates={secondaryDisplayDates}
                        secondaryDisplayValuesByDate={
                          secondaryDisplayValuesByDate
                        }
                        /*------------------------------------------------*/
                        isTotalActualTimeDataAvailable={
                          isTotalActualTimeDataAvailable
                        }
                        isTotalActualTimeLoading={isTotalActualTimeLoading}
                        totalActualTimeDates={totalActualTimeDates}
                        totalActualTimeByDate={totalActualTimeByDate}
                      />
                      {/* <div className="row justify-content-center mt-3">
                                                <div className="col-5">
                                                    <div className='card'>
                                                        <label htmlFor="" className='table_label'>Visit Wave</label>
                                                        {(visitsPerStoreRows?.length > 0) &&
                                                            <DynamicDataTable columnDetails={visitsPerStoreColumnDetails}
                                                                rowID={'ID'}
                                                                columnHeaders={visitsPerStoreColumnHeaders}
                                                                rows={visitsPerStoreRows}
                                                                isLoading={visitsPerStoreTableLoading}
                                                                setPageSize={setVisitsPerStorePageSize}
                                                                pageSize={visitsPerStorePageSize}
                                                                setPage={setVisitsPerStorePage}
                                                                page={visitsPerStorePage}
                                                                rowCount={visitsPerStoreRowCount}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                            </div> */}
                    </>
                  )}
                </Tab>
              ))}
            </Tabs>
          )}
        </>
      )}
    </div>
  );
};

export default MonitorPerformance;
