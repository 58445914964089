import axios from "axios";
import React, { useEffect, useState } from "react";
import { Alert, Dropdown } from "react-bootstrap";
import { BiReset } from "react-icons/bi";
import "../../styles/monitorAnalytics.scss";
import LogUserActivity from "../../components/LogUserActivity";
const ImageDownload = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isPhotosLoading, setIsPhotosLoading] = useState(false);
  const [alertError, setAlertError] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(false);
 const [logId, setLogId] = useState(null);
  // FILTER DROPDOWN  OPTIONS
  const [monthFilters, setMonthFilters] = useState([]);
  const [yearFilters, setYearFilters] = useState([]);
  const [categoryFilters, setCategoryFilters] = useState([]);
  const [customerFilters, setCustomerFilters] = useState([]);
  const [cityFilters, setCityFilters] = useState([]);

  // SELECTED FILTER VALUE
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [categorySelectedAll, setCategorySelectedAll] = useState(false);
  const [customerSelectedAll, setCustomerSelectedAll] = useState(false);
  const [citySelectedAll, setCitySelectedAll] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(false);

  // REGIONS DATA
  const [customersToShow, setCustomersToShow] = useState([]);
  const [citiesToShow, setCitiesToShow] = useState([]);
  const [categoriesToShow, setCategoriesToShow] = useState([]);

  // CSV DOWNLOAD
  const [csvData, setCSVData] = useState();
  const [flyerDataButton, setFlyerDataButton] = useState(false);

  useEffect(() => {
    const currentMonth = new Date().getMonth(); // Adding 1 because getMonth() returns a zero-based index
    const currentYear = new Date().getFullYear();
    let fiscalYearStartYear;
  
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
  
    fiscalYearStartYear = currentMonth >= 6 ? currentYear : currentYear - 1;
  
    const fiscalYearLabel = `FY${fiscalYearStartYear.toString().slice(-2)}${(
      fiscalYearStartYear + 1
    )
      .toString()
      .slice(-2)}`;
  
    setSelectedYear(fiscalYearLabel);
    setSelectedMonth(monthNames[currentMonth]);
    setIsFirstTime(true);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const getFilterValues = async () => {
      await axios({
        method: "post",
        url: "/api/filter_values_on_shelf",
      })
        .then((response) => {
          if (response?.request?.status === 200) {
            setMonthFilters(response?.data?.month);
            setCategoryFilters(response?.data?.category);
            setCustomerFilters(response?.data?.customer);
            setCityFilters(response?.data?.city);
            setYearFilters(response?.data?.year);
            console.log(response, "filter values");
          }
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };
LogUserActivity("Monitor Analytics imageDownload Activity Started", props?.currentUser?.user, setLogId, setAlertError);
    getFilterValues();
  }, []);

  const selectAll = (e, flag) => {
    if (flag === "category") {
      if (!e.target.checked) {
        setCategoriesToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          categoryFilters.map((category) => {
            return category;
          })
        );

        setCategoriesToShow(filterArray[0]);
      }

      setCategorySelectedAll(e.target.checked);
    }

    if (flag === "city") {
      if (!e.target.checked) {
        setCitiesToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          cityFilters.map((city) => {
            return city;
          })
        );

        setCitiesToShow(filterArray[0]);
      }

      setCitySelectedAll(e.target.checked);
    }
    if (flag === "customer") {
      if (!e.target.checked) {
        setCustomersToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          customerFilters.map((customer) => {
            return customer;
          })
        );

        setCustomersToShow(filterArray[0]);
      }

      setCustomerSelectedAll(e.target.checked);
    }
  };

  const checkBoxFilterHandle = (e, flag) => {
    const { checked, value } = e.target;

    if (flag === "category") {
      if (checked) {
        const checkedValue = categoriesToShow.includes(value);

        if (!checkedValue) {
          const tempCategoryFilters = [...categoriesToShow, value];
          setCategoriesToShow(tempCategoryFilters);

          if (tempCategoryFilters.length === categoryFilters.length) {
            setCategorySelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = categoriesToShow.filter((element) => {
          return element !== value;
        });

        setCategoriesToShow(uncheckedValue);
        setCategorySelectedAll(false);
      }
    }
    if (flag === "customer") {
      if (checked) {
        const checkedValue = customersToShow.includes(value);

        if (!checkedValue) {
          const tempCustomerFilters = [...customersToShow, value];
          setCustomersToShow(tempCustomerFilters);

          if (tempCustomerFilters.length === customerFilters.length) {
            setCustomerSelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = customersToShow.filter((element) => {
          return element !== value;
        });

        setCustomersToShow(uncheckedValue);
        setCustomerSelectedAll(false);
      }
    }
    if (flag === "city") {
      if (checked) {
        const checkedValue = citiesToShow.includes(value);

        if (!checkedValue) {
          const tempCityFilters = [...citiesToShow, value];
          setCitiesToShow(tempCityFilters);

          if (tempCityFilters.length === cityFilters.length) {
            setCitySelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = citiesToShow.filter((element) => {
          return element !== value;
        });

        setCitiesToShow(uncheckedValue);
        setCitySelectedAll(false);
      }
    }
  };

  const downloadCSV = async () => {
    setIsPhotosLoading(true);
    await axios({
      method: "post",
      url: "/api/images_download",
      timeout: 1500000,
      data: {
        category: categoriesToShow,
        month: selectedMonth,
        year: selectedYear,
        city: citiesToShow,
        customer: customersToShow,
      },
    })
      .then((response) => {
        if (response?.request?.status === 200) {
          setIsPhotosLoading(false);
          setAlertSuccess(response?.data?.message);
          console.log(response, "filter values");
        }
      })
      .catch((error) => {
        console.log("Filter Arrays Api: ", error);
        setIsPhotosLoading(false);
        setAlertError("There is a problem in the server. Please contact site admin.");
      });
  };

  return (
    <div className="container-fluid monitor_performance position-relative">
      <h2 className="heading_sm_center">Download Images</h2>
      {alertSuccess && <Alert variant="success">{alertSuccess}</Alert>}
      {alertError && <Alert variant="danger">{alertError}</Alert>}
      <div className="row content_main_row">
        <div className="filters col-md-12">
          <div className="row w-100 filters_row justify-content-between">
            <div className="col py-0">
              <div
                className="filters_right w-100 justify-content-center"
                style={{ gap: "5px" }}
              >
                <div className="filter-dropdowns">
                  <Dropdown
                    className={`${
                      !selectedYear ? "dropDown_disabled" : "dropdown_selected"
                    }`}
                  >
                    <Dropdown.Toggle variant="secondary">
                      {selectedYear ? selectedYear : "Year"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {yearFilters?.map((year, index) => (
                        <Dropdown.Item
                          key={index}
                          onClick={(event) =>
                            setSelectedYear(event.target.text)
                          }
                        >
                          {year}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="filter-dropdowns">
                  <Dropdown
                    className={`${
                      !selectedMonth ? "dropDown_disabled" : "dropdown_selected"
                    }`}
                  >
                    <Dropdown.Toggle variant="secondary">
                      {selectedMonth ? selectedMonth : "Month"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {monthFilters?.map((month, index) => (
                        <Dropdown.Item
                          key={index}
                          onClick={(event) =>
                            setSelectedMonth(event.target.text)
                          }
                        >
                          {month}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className="col-md-9 col-xl-8">
              <div
                className="filters_right w-100 justify-content-center"
                style={{ gap: "5px" }}
              >
                <div className="filter-dropdowns">
                  <Dropdown
                    className={`${
                      !(citiesToShow.length > 0)
                        ? "dropDown_disabled"
                        : "dropdown_selected"
                    }`}
                  >
                    <Dropdown.Toggle variant="secondary">city</Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div
                        className="p-3 d-flex flex-column"
                        style={{ gap: "5px" }}
                      >
                        <div className="d-flex" style={{ gap: "0 8px" }}>
                          <input
                            type="checkbox"
                            name="selectAll"
                            checked={citySelectedAll}
                            onChange={(e) => selectAll(e, "city")}
                          />
                          <label htmlFor="selectAll">Select All</label>
                        </div>

                        {cityFilters?.map((city, index) => (
                          <div
                            key={index}
                            className="d-flex"
                            style={{ gap: "0 10px" }}
                          >
                            <input
                              type="checkbox"
                              value={city}
                              onChange={(e) => checkBoxFilterHandle(e, "city")}
                              checked={
                                citiesToShow.includes(city) ? true : false
                              }
                            />
                            <label style={{ fontSize: "12px" }}>{city}</label>
                          </div>
                        ))}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <div className="filter-dropdowns">
                  <Dropdown
                    className={`${
                      !(categoriesToShow.length > 0)
                        ? "dropDown_disabled"
                        : "dropdown_selected"
                    }`}
                  >
                    <Dropdown.Toggle variant="secondary">
                      category
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div
                        className="p-3 d-flex flex-column"
                        style={{ gap: "5px" }}
                      >
                        <div className="d-flex" style={{ gap: "0 8px" }}>
                          <input
                            type="checkbox"
                            name="selectAll"
                            checked={categorySelectedAll}
                            onChange={(e) => selectAll(e, "category")}
                          />
                          <label htmlFor="selectAll">Select All</label>
                        </div>

                        {categoryFilters?.map((category, index) => (
                          <div
                            key={index}
                            className="d-flex"
                            style={{ gap: "0 10px" }}
                          >
                            <input
                              type="checkbox"
                              value={category}
                              onChange={(e) =>
                                checkBoxFilterHandle(e, "category")
                              }
                              checked={
                                categoriesToShow.includes(category)
                                  ? true
                                  : false
                              }
                            />
                            <label style={{ fontSize: "12px" }}>
                              {category}
                            </label>
                          </div>
                        ))}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <div className="filter-dropdowns">
                  <Dropdown
                    className={`${
                      !(customersToShow.length > 0)
                        ? "dropDown_disabled"
                        : "dropdown_selected"
                    }`}
                  >
                    <Dropdown.Toggle variant="secondary">
                      customer
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div
                        className="p-3 d-flex flex-column"
                        style={{ gap: "5px" }}
                      >
                        <div className="d-flex" style={{ gap: "0 8px" }}>
                          <input
                            type="checkbox"
                            name="selectAll"
                            checked={customerSelectedAll}
                            onChange={(e) => selectAll(e, "customer")}
                          />
                          <label htmlFor="selectAll">Select All</label>
                        </div>

                        {customerFilters?.map((customer, index) => (
                          <div
                            key={index}
                            className="d-flex"
                            style={{ gap: "0 10px" }}
                          >
                            <input
                              type="checkbox"
                              value={customer}
                              onChange={(e) =>
                                checkBoxFilterHandle(e, "customer")
                              }
                              checked={
                                customersToShow.includes(customer)
                                  ? true
                                  : false
                              }
                            />
                            <label style={{ fontSize: "12px" }}>
                              {customer}
                            </label>
                          </div>
                        ))}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-4 card p-4">
          <div className="template_buttons">
            <div className="row w-100 justify-content-center template_filters">
              <div className="col-6 col-md-4 m-0 text_sm_center p-0">
                <button
                  onClick={() => downloadCSV()}
                  className="btn btn-primary"
                  style={{ width: "120px", height: "32px" }}
                >
                  {isPhotosLoading ? (
                    <div
                      className="spinner-border text-info"
                      role="status"
                      style={{ zIndex: "2", width: "20px", height: "20px" }}
                    ></div>
                  ) : (
                    "Download"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageDownload;
