import React from "react";

const StaticDownload = ({ rows, columnHeaders, columnDetails, fileName }) => {
  const downloadCSV = () => {
    if (rows?.length > 0) {
      // Convert rows to CSV format
      const csvContent = [
        columnHeaders.join(","), // Add column headers as the first row
        ...rows.map((row) =>
            columnHeaders.map((col) => row[col]).join(",")
        ), // Map each row's data
      ].join("\n"); // Join rows with a new line

      // Create a blob and link to download the CSV
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", `${fileName}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up the DOM after download
    } else {
      alert("No data available to download.");
    }
  };

  return (
    <button onClick={downloadCSV} className="btn btn-primary">
      Download
    </button>
  );
};

export default StaticDownload;
