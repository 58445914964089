import React, { useState, useEffect } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { BiReset } from "react-icons/bi";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import axios from "axios";
import { Alert, Tabs, Tab } from "react-bootstrap";
import "../../styles/monitorAnalytics.scss";
import MultiTuple from "../../components/MultiTuple";
import LogUserActivity from "../../components/LogUserActivity";

const TargetVsActual = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [alertError, setAlertError] = useState(null);
 const [logId, setLogId] = useState(null);
  /* TAB VARIABLES */
  const [tabs, setTabs] = useState([]);
  const [isFirstTabSelected, setIsFirstTabSelected] = useState(false);
  const [selectedTab, setSelectedTab] = useState("jafar.Aldawood");

  /* FILTER VARIABLES */
  const [yearFilters, setYearFilters] = useState([]);
  const [weekFilters, setWeekFilters] = useState([]);
  const [monthFilters, setMonthFilters] = useState([]);
  const [isFilterable, setIsFilterable] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  /* SELECTED FILTER VARIABLES */
  const [tempSelectedYear, setTempSelectedYear] = useState("FY2223");
  const [selectedYear, setSelectedYear] = useState("");
  const [tempSelectedWeek, setTempSelectedWeek] = useState("1");
  const [selectedWeek, setSelectedWeek] = useState("1");
  const [tempSelectedMonth, setTempSelectedMonth] = useState("January");
  const [selectedMonth, setSelectedMonth] = useState("January");

  /* CHART VARIABLES */
  const [isTargetVsActualDataAvailable, setIsTargetVsActualDataAvailable] =
    useState(false);
  const [monitors, setMonitors] = useState([]);
  const [targetVsActualData, setTargetVsActualData] = useState([]);

  useEffect(() => {
    const currentMonth = new Date().getMonth() + 1; // Adding 1 because getMonth() returns a zero-based index
    const currentYear = new Date().getFullYear();
    let fiscalYearStartYear;

    // Define your organization's fiscal year start month and the corresponding starting year
    const fiscalYearStartMonth = 4; // Fiscal year starts in April

    if (currentMonth >= fiscalYearStartMonth) {
      fiscalYearStartYear = currentYear;
    } else {
      fiscalYearStartYear = currentYear - 1;
    }

    const fiscalYearLabel = `FY${(fiscalYearStartYear - 1)
      .toString()
      .slice(-2)}${fiscalYearStartYear.toString().slice(-2)}`;
    setSelectedYear(fiscalYearLabel);
  }, []);

  const ITEM_HEIGHT = 50;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5,
        width: 200,
      },
    },
  };

  useEffect(() => {
    const getYearFilters = async () => {
      setIsLoading(true);
      setAlertError(null);
      await axios({
        method: "post",
        url: "/api/year",
      })
        .then((response) => {
          console.log(response, "years");
          setYearFilters(response?.data?.data);
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };
    LogUserActivity("Target_Vs_Actual Activity Started", props?.currentUser?.user, setLogId, setAlertError);
    getYearFilters();
  }, []);

  useEffect(() => {
    const getMonthFilters = async () => {
      setIsLoading(true);
      setAlertError(null);
      await axios({
        method: "post",
        url: "/api/month",
        data: {
          year: tempSelectedYear,
        },
      })
        .then((response) => {
          setMonthFilters(response?.data?.data);
          console.log(response, " month");
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };
    if (tempSelectedYear) {
      getMonthFilters();
    }
  }, [tempSelectedYear]);

  useEffect(() => {
    const getWeekFilters = async () => {
      setIsLoading(true);
      setAlertError(null);
      await axios({
        method: "post",
        url: "/api/week",
        data: {
          month: tempSelectedMonth,
        },
      })
        .then((response) => {
          setWeekFilters(response?.data?.data);
          console.log(response, "weeks");
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };
    if (tempSelectedMonth) {
      getWeekFilters();
    }
  }, [tempSelectedMonth]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setAlertError(null);

      await axios({
        method: "post",
        url: "/api/actual_store_target",
        headers: { "Content-Type": "application/json" },
        data: {
          username: "",
          year: selectedYear,
          week: "1",
          month: "January",
        },
      })
        .then((response) => {
          setIsLoading(false);

          if (response.status === 200) {
            setIsTargetVsActualDataAvailable(true);

            const responseData = response.data;
            const targetData = responseData.target.target;
            const targetMonitors = Object.keys(targetData);
            const targetValues = Object.values(targetData);
            const actualData = responseData["store visited"].actual;
            const actualMonitors = Object.keys(actualData);
            const actualValues = Object.values(actualData);
            let datasets = [];
            let tempActualValues = [];

            datasets.push({
              label: "Target",
              data: targetValues,
              backgroundColor: "#131052",
            });

            for (let i = 0; i < targetMonitors.length; i++) {
              const foundIndex = actualMonitors.findIndex(
                (monitor) => monitor === targetMonitors[i]
              );

              if (foundIndex > -1) {
                tempActualValues.push(actualValues[foundIndex]);
              } else {
                tempActualValues.push(0);
              }
            }

            datasets.push({
              label: "Actual",
              data: tempActualValues,
              backgroundColor: "#059298",
            });

            let tabs = Object.values(responseData["store visited"].userName);
            console.log(response, "response");
            // tabs.unshift("All Monitors");
            setTabs(tabs);

            setIsFirstTabSelected(true);

            setMonitors(tabs);
            setTargetVsActualData(datasets);
            setIsFiltered(false);
          } else if (response.status === 202) {
            setIsTargetVsActualDataAvailable(false);
            console.log(response, "response");
            const responseData = response.data;
            const tabs = responseData?.monitorsList.map(item => item.userName);
            // tabs.unshift("All Monitors");
            setTabs(tabs);
            setIsFiltered(false);
            setIsFirstTabSelected(true);
          }
        })
        .catch((error) => {
          setIsFiltered(false);
          console.log("Target Vs Actual by Month Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };

    fetchData();
  }, []);
  console.log(tabs, "tabs");
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setAlertError(null);

      await axios({
        method: "post",
        url: "/api/actual_store_target",
        headers: { "Content-Type": "application/json" },
        data: {
          username: selectedTab,
          year: selectedYear,
          week: selectedWeek,
          month: selectedMonth,
        },
      })
        .then((response) => {
          setIsLoading(false);

          if (response.status === 200) {
            setIsTargetVsActualDataAvailable(true);

            const responseData = response.data;
            const targetData = responseData.target.target;
            const targetMonitors = Object.keys(targetData);
            const targetValues = Object.values(targetData);
            const actualData = responseData["store visited"].actual;
            const actualMonitors = Object.keys(actualData);
            const actualValues = Object.values(actualData);
            let datasets = [];
            let tempActualValues = [];

            datasets.push({
              label: "Target",
              data: targetValues,
              backgroundColor: "#131052",
            });

            for (let i = 0; i < targetMonitors.length; i++) {
              const foundIndex = actualMonitors.findIndex(
                (monitor) => monitor === targetMonitors[i]
              );

              if (foundIndex > -1) {
                tempActualValues.push(actualValues[foundIndex]);
              } else {
                tempActualValues.push(0);
              }
            }

            datasets.push({
              label: "Actual",
              data: tempActualValues,
              backgroundColor: "#059298",
            });
            // let tabs = Object.values(responseData["store visited"].userName);
            // tabs.unshift("All Monitors");
            // setTabs(tabs);
            let tabs = Object.values(responseData["store visited"].userName);
            setMonitors(tabs);
            setIsFiltered(false);
            setTargetVsActualData(datasets);
          } else if (response.status === 202) {
            // tabs.unshift("All Monitors");
            setIsFiltered(false);
            setIsTargetVsActualDataAvailable(false);
          }
        })
        .catch((error) => {
          setIsFiltered(false);
          console.log("Target Vs Actual by Month Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };

    fetchData();
  }, [selectedTab, selectedYear, selectedWeek, selectedMonth]);

  useEffect(() => {
    if (tempSelectedWeek || tempSelectedMonth || tempSelectedYear) {
      setIsFilterable(true);
    } else {
      setIsFilterable(false);
    }
  }, [tempSelectedWeek, tempSelectedMonth, tempSelectedYear]);

  const handleSelectTab = (eventKey) => {
    if (parseInt(eventKey) === 0) {
      setSelectedTab("");
    } else {
      setSelectedTab(eventKey);
    }
  };

  const handleChange = (event) => {
    const {
      target: { name, value },
    } = event;

    if (name === "week") {
      setTempSelectedWeek(value);
    } else if (name === "month") {
      setTempSelectedMonth(value);
      setTempSelectedWeek(null);
    } else if (name === "year") {
      setTempSelectedYear(value);
      setTempSelectedWeek(null);
      setTempSelectedMonth(null);
    }
  };

  const handleFilter = () => {
    setSelectedYear(tempSelectedYear);
    setSelectedWeek(tempSelectedWeek);
    setSelectedMonth(tempSelectedMonth);
    setIsFiltered(true);
  };

  const handleReset = () => {
    setTempSelectedYear("2021");
    setSelectedYear("2021");
    setTempSelectedWeek("1");
    setSelectedWeek("1");
    setTempSelectedMonth("january");
    setSelectedMonth("january");
    setIsFilterable(false);
    setIsFiltered(false);
  };

  return (
    <div className="container-fluid monitor_performance position-relative">
      <h2 className="heading_sm_center">Target Vs Actual</h2>

      {isLoading &&
        tabs.length < 1 &&
        yearFilters.length < 1 &&
        monthFilters.length < 1 &&
        weekFilters.length < 1 && (
          <div className="col-12 d-flex justify-content-center loader">
            <br />
            <div
              className="spinner-border text-info"
              role="status"
              style={{ zIndex: "2" }}
            ></div>
            <div className="loader-message">Loading...</div>
          </div>
        )}
      <>
        {alertError && <Alert variant="danger">{alertError}</Alert>}

        {tabs && tabs.length > 0 && (
          <Tabs
            defaultActiveKey={selectedTab ? selectedTab : "All Monitors"}
            className="mb-3"
            justify={true}
            onSelect={handleSelectTab}
          >
            {tabs.map((tab, index) => (
              <Tab key={index} eventKey={tab} title={tab}>
                <div className="row filter-container">
                  <div
                    className="col-6 col-md-3 m-0 filters"
                    style={{ marginTop: "2em" }}
                  >
                    <FormControl sx={{ width: 200 }} size="small">
                      <InputLabel id="year" style={{ fontSize: "14px" }}>
                        Year
                      </InputLabel>
                      <Select
                        labelId="year"
                        multiple={false}
                        name="year"
                        value={tempSelectedYear}
                        onChange={handleChange}
                        input={<OutlinedInput label="Year" />}
                        renderValue={() => tempSelectedYear}
                        MenuProps={MenuProps}
                      >
                        {/* <MenuItem value = ""><em>None</em></MenuItem> */}
                        {yearFilters.map((year) => (
                          <MenuItem key={year} value={year}>
                            {year}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-6 col-md-3 m-0 filters">
                    <FormControl sx={{ width: 200 }} size="small">
                      <InputLabel id="month" style={{ fontSize: "14px" }}>
                        Month
                      </InputLabel>
                      <Select
                        labelId="month"
                        name="month"
                        multiple={false}
                        value={tempSelectedMonth}
                        onChange={handleChange}
                        input={<OutlinedInput label="Month" />}
                        renderValue={() => tempSelectedMonth}
                        MenuProps={MenuProps}
                      >
                        {/* <MenuItem value = ""><em>None</em></MenuItem> */}
                        {monthFilters.map((month) => (
                          <MenuItem key={month} value={month}>
                            {month}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-6 col-md-3 m-0 filters">
                    <FormControl sx={{ width: 200 }} size="small">
                      <InputLabel id="week" style={{ fontSize: "14px" }}>
                        Week
                      </InputLabel>
                      <Select
                        labelId="week"
                        multiple={false}
                        name="week"
                        value={tempSelectedWeek}
                        onChange={handleChange}
                        input={<OutlinedInput label="Week" />}
                        renderValue={() => tempSelectedWeek}
                        MenuProps={MenuProps}
                      >
                        {/* <MenuItem value = ""><em>None</em></MenuItem> */}
                        {weekFilters.map((week) => (
                          <MenuItem key={week} value={week}>
                            {week}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-6 col-md-3 m-0 filters">
                    <div className="btn-group btn-contained" role="group">
                      <button
                        className="filterButton btn btn-success"
                        color="primary"
                        disabled={!isFilterable}
                        onClick={handleFilter}
                      >
                        Filter
                      </button>
                      <button
                        className="resetButton btn btn-light btn-outline"
                        disabled={!isFiltered}
                        onClick={handleReset}
                      >
                        <BiReset />
                      </button>
                    </div>
                  </div>
                </div>
                <br />

                {isLoading &&
                selectedTab &&
                isFirstTabSelected &&
                isFiltered ? (
                  <div className="col-12 d-flex justify-content-center loader">
                    <br />
                    <div
                      className="spinner-border text-info"
                      role="status"
                      style={{ zIndex: "2" }}
                    ></div>
                    <div className="loader-message">Loading...</div>
                  </div>
                ) : (
                  <div
                    className={`${
                      isTargetVsActualDataAvailable ? "" : "no-data-container"
                    }`}
                    style={{ height: "400px" }}
                  >
                    {isTargetVsActualDataAvailable ? (
                      <MultiTuple
                        title="Target Vs Actual Store Visits"
                        labels={monitors}
                        datasets={targetVsActualData}
                      />
                    ) : (
                      <div className="no-data">
                        <span>Target Vs Actual Store Visits</span>
                        <span>
                          <small>No data found.</small>
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </Tab>
            ))}
          </Tabs>
        )}
      </>
    </div>
  );
};

export default TargetVsActual;
