import axios from "axios";
import React, { useState, useEffect } from "react";
import { Alert, Dropdown, Table } from "react-bootstrap";
import CustomDataMap from "../../components/CustomDataMap";
import DoubleBarChart from "../../components/DoubleBarChart";
import LineChart from "../../components/LineChart";
import "../../styles/monitorAnalytics.scss";
import LogUserActivity from "../../components/LogUserActivity";

const RSP = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [alertError, setAlertError] = useState(false);
 const [logId, setLogId] = useState(null);
  // FILTER DROPDOWN  OPTIONS
  const [cityFilters, setCityFilters] = useState([]);
  const [competitionFilters, setCompetitionFilters] = useState(["hhk", "sgjj"]);
  const [monthFilters, setMonthFilters] = useState([]);
  const [yearFilters, setYearFilters] = useState([]);
  const [categoryFilters, setCategoryFilters] = useState([]);
  const [customerFilters, setCustomerFilters] = useState([]);
  const [skuFilters, setSkuFilters] = useState([]);
  const [skuFiltersList, setSkuFiltersList] = useState([]);
  const [skuSearchInput, setSkuSearchInput] = useState(null);
  // SELECTED FILTER VALUE
  const [citySelectedAll, setCitySelectedAll] = useState(false);
  const [monthSelectedAll, setMonthSelectedAll] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState("January");
  const [selectedYear, setSelectedYear] = useState("");
  const [categorySelectedAll, setCategorySelectedAll] = useState(false);
  const [customerSelectedAll, setCustomerSelectedAll] = useState(false);
  const [skuSelectedAll, setSkuSelectedAll] = useState(false);
  const [regionSelectedAll, setCompetitionsSelectedAll] = useState(false);

  /* CHART DATASET VARIABLES */

  const [citiesToShow, setCitiesToShow] = useState(["Jeddah"]);
  const [monthsToShow, setMonthsToShow] = useState(["January"]);
  const [customersToShow, setCustomersToShow] = useState(["Panda"]);
  const [competitionsToShow, setCompetitionsToShow] = useState(["Clorox"]);
  const [categoriesToShow, setCategoriesToShow] = useState(["Spray"]);
  const [skuToShow, setSkuToShow] = useState(["Clorox409 Reg 12*750ml"]);

  // CHART DATA
  const [lineGraphdata, setLineGraphdata] = useState(null);

  useEffect(() => {
    const currentMonth = new Date().getMonth() + 1; // Adding 1 because getMonth() returns a zero-based index
    const currentYear = new Date().getFullYear();
    let fiscalYearStartYear;

    // Define your organization's fiscal year start month and the corresponding starting year
    const fiscalYearStartMonth = 4; // Fiscal year starts in April

    if (currentMonth >= fiscalYearStartMonth) {
        fiscalYearStartYear = currentYear;
    } else {
        fiscalYearStartYear = currentYear - 1;
    }

    const fiscalYearLabel = `FY${((fiscalYearStartYear - 1).toString()).slice(-2)}${(fiscalYearStartYear).toString().slice(-2)}`;
    setSelectedYear(fiscalYearLabel);
}, []);

  useEffect(() => {
    setIsLoading(true);
    const getMonthFilters = async () => {
      await axios({
        method: "post",
        url: "/api/monthList_RSP",
        data: {
          year: selectedYear,
        },
      })
        .then((response) => {
          setIsLoading(false);
          console.log(response, "month");
          setMonthFilters(response?.data?.data);
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };
    if (selectedYear) {
      getMonthFilters();
    }
  }, [selectedYear]);

  useEffect(() => {
    setIsLoading(true);
    const getYearFilters = async () => {
      await axios({
        method: "post",
        url: "/api/yearList_RSP",
      })
        .then((response) => {
          setIsLoading(false);
          console.log(response, "year");
          setYearFilters(response?.data?.data);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log("Filter Arrays Api: ", error);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };
    LogUserActivity("RSP Activity Started", props?.currentUser?.user, setLogId, setAlertError);
    getYearFilters();
  }, []);

  useEffect(() => {
    setAlertError("");
    setIsLoading(true);

    const getDefaultData = async () => {
      await axios({
        method: "post",
        url: "/api/rsp_line_graph",
        data: JSON.stringify({
          city: citiesToShow,
          customer: customersToShow,
          category: categoriesToShow,
          comp: competitionsToShow,
          sku: skuToShow,
          month: monthsToShow,
          year: selectedYear,
        }),
      })
        .then((response) => {
          if (response?.request?.status === 200) {
            setIsLoading(false);
            if (response?.data?.data !== "no data found") {
              setLineGraphdata(response?.data);
            }
            console.log(response, "line graph reonse");
          }
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };

    getDefaultData();
  }, []);
  useEffect(() => {
    const getCityFilters = async () => {
      await axios({
        method: "post",
        url: "/api/cityList",
        data: {
          month: monthsToShow,
        },
      })
        .then((response) => {
          if (response?.request?.status === 200) {
            setCityFilters(response?.data?.data);
            setIsLoading(false);
            console.log(response, "filter");
          }
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };
    if (monthsToShow.length > 0) {
      getCityFilters();
    }
  }, [monthsToShow]);

  useEffect(() => {
    setIsLoading(true);
    const getCustomerFilters = async () => {
      await axios({
        method: "post",
        url: "/api/storeList",
        data: JSON.stringify({
          city: citiesToShow,
        }),
      })
        .then((response) => {
          if (response?.request?.status === 200) {
            setCustomerFilters(response?.data?.data);
            setIsLoading(false);
            console.log(response, "filter values");
          }
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };

    if (citiesToShow.length > 0) {
      getCustomerFilters();
    }
  }, [citiesToShow]);

  useEffect(() => {
    setIsLoading(true);

    const getCategorieFilters = async () => {
      await axios({
        method: "post",
        url: "/api/categoryList",
        data: JSON.stringify({
          store: customersToShow,
        }),
      })
        .then((response) => {
          if (response?.request?.status === 200) {
            setCategoryFilters(response?.data?.data);
            setIsLoading(false);
            console.log(response, "filter values");
          }
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };

    if (customersToShow.length > 0) {
      getCategorieFilters();
    }
  }, [customersToShow]);

  useEffect(() => {
    setIsLoading(true);

    const getCompetitionFilters = async () => {
      await axios({
        method: "post",
        url: "/api/compList",
        data: JSON.stringify({
          category: categoriesToShow,
        }),
      })
        .then((response) => {
          if (response?.request?.status === 200) {
            setCompetitionFilters(response?.data?.data);
            setIsLoading(false);
            console.log(response, "filter values");
          }
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };

    if (categoriesToShow.length > 0) {
      getCompetitionFilters();
    }
  }, [categoriesToShow]);

  useEffect(() => {
    setIsLoading(true);

    const getSkuFilters = async () => {
      await axios({
        method: "post",
        url: "/api/skuList",
        data: JSON.stringify({
          comp: competitionsToShow,
        }),
      })
        .then((response) => {
          if (response?.request?.status === 200) {
            setSkuFilters(response?.data?.data);
            setSkuFiltersList(response?.data?.data);
            setIsLoading(false);
            console.log(response, "filter values");
          }
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };

    if (competitionsToShow.length > 0) {
      getSkuFilters();
    }
  }, [competitionsToShow]);

  const selectAll = (e, flag) => {
    if (flag === "city") {
      if (!e.target.checked) {
        setCitiesToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          cityFilters.map((city) => {
            return city;
          })
        );

        setCitiesToShow(filterArray[0]);
      }

      setCitySelectedAll(e.target.checked);
    }
    if (flag === "month") {
      if (!e.target.checked) {
        setMonthsToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          monthFilters.map((month) => {
            return month;
          })
        );

        setMonthsToShow(filterArray[0]);
      }

      setMonthSelectedAll(e.target.checked);
    }
    if (flag === "category") {
      if (!e.target.checked) {
        setCategoriesToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          categoryFilters.map((category) => {
            return category;
          })
        );

        setCategoriesToShow(filterArray[0]);
      }

      setCategorySelectedAll(e.target.checked);
    }

    if (flag === "sku") {
      if (!e.target.checked) {
        setSkuToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          skuFilters.map((city) => {
            return city;
          })
        );

        setSkuToShow(filterArray[0]);
      }

      setSkuSelectedAll(e.target.checked);
    }
    if (flag === "competition") {
      if (!e.target.checked) {
        setCompetitionsToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          competitionFilters.map((region) => {
            return region;
          })
        );

        setCompetitionsToShow(filterArray[0]);
      }

      setCompetitionsSelectedAll(e.target.checked);
    }
    if (flag === "customer") {
      if (!e.target.checked) {
        setCustomersToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          customerFilters.map((customer) => {
            return customer;
          })
        );

        setCustomersToShow(filterArray[0]);
      }

      setCustomerSelectedAll(e.target.checked);
    }

    // if (flag === "Competitions") {
    //     if (!e.target.checked) {
    //         setCompetitionsToShow([]);
    //     }
    //     else {
    //         let filterArray = [];

    //         filterArray.push(competitionFilters.map((competition) => {
    //             return competition;
    //         }));

    //         setCompetitionsToShow(filterArray[0]);
    //     }

    //     setCompetitionSelectedAll(e.target.checked);
    // }
  };
  const checkBoxFilterHandle = (e, flag) => {
    const { checked, value } = e.target;
    if (flag === "month") {
      setCityFilters([]);
      setCitiesToShow([]);
      setCustomersToShow([]);
      setCustomerFilters([]);
      setCategoriesToShow([]);
      setCategoryFilters([]);
      setCompetitionsToShow([]);
      setCompetitionFilters([]);
      setSkuToShow([]);
      setSkuFilters([]);
      if (checked) {
        const checkedValue = monthsToShow.includes(value);

        if (!checkedValue) {
          const tempMonthFilters = [...monthsToShow, value];
          setMonthsToShow(tempMonthFilters);

          if (tempMonthFilters.length === monthFilters.length) {
            setMonthSelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = monthsToShow.filter((element) => {
          return element !== value;
        });

        setMonthsToShow(uncheckedValue);
        setMonthSelectedAll(false);
      }
    }
    if (flag === "city") {
      setCustomersToShow([]);
      setCustomerFilters([]);
      setCategoriesToShow([]);
      setCategoryFilters([]);
      setCompetitionsToShow([]);
      setCompetitionFilters([]);
      setSkuToShow([]);
      setSkuFilters([]);
      if (checked) {
        const checkedValue = citiesToShow.includes(value);

        if (!checkedValue) {
          const tempCityFilters = [...citiesToShow, value];
          setCitiesToShow(tempCityFilters);

          if (tempCityFilters.length === cityFilters.length) {
            setCitySelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = citiesToShow.filter((element) => {
          return element !== value;
        });

        setCitiesToShow(uncheckedValue);
        setCitySelectedAll(false);
      }
    }
    if (flag === "category") {
      setCategoriesToShow([]);
      setCategoryFilters([]);
      setCompetitionsToShow([]);
      setCompetitionFilters([]);
      setSkuToShow([]);
      setSkuFilters([]);
      if (checked) {
        const checkedValue = categoriesToShow.includes(value);

        if (!checkedValue) {
          const tempCategoryFilters = [...categoriesToShow, value];
          setCategoriesToShow(tempCategoryFilters);

          if (tempCategoryFilters.length === categoryFilters.length) {
            setCategorySelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = categoriesToShow.filter((element) => {
          return element !== value;
        });

        setCategoriesToShow(uncheckedValue);
        setCategorySelectedAll(false);
      }
    }
    if (flag === "customer") {
      if (checked) {
        const checkedValue = customersToShow.includes(value);

        if (!checkedValue) {
          const tempCustomerFilters = [...customersToShow, value];
          setCustomersToShow(tempCustomerFilters);

          if (tempCustomerFilters.length === customerFilters.length) {
            setCustomerSelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = customersToShow.filter((element) => {
          return element !== value;
        });

        setCustomersToShow(uncheckedValue);
        setCustomerSelectedAll(false);
      }
    }
    if (flag === "sku") {
      setSkuToShow([]);
      //   setSkuFilters([]);
      if (checked) {
        const checkedValue = skuToShow.includes(value);

        if (!checkedValue) {
          const tempskuFilters = [...skuToShow, value];
          setSkuToShow(tempskuFilters);

          if (tempskuFilters.length === skuFilters.length) {
            setSkuSelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = skuToShow.filter((element) => {
          return element !== value;
        });

        setSkuToShow(uncheckedValue);
        setSkuSelectedAll(false);
      }
    }
    if (flag === "competition") {
      setCompetitionsToShow([]);
      setCompetitionFilters([]);
      setSkuToShow([]);
      setSkuFilters([]);
      if (checked) {
        const checkedValue = competitionsToShow.includes(value);

        if (!checkedValue) {
          const tempcompetitionFilters = [...competitionsToShow, value];
          setCompetitionsToShow(tempcompetitionFilters);

          if (tempcompetitionFilters.length === competitionFilters.length) {
            setCompetitionsSelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = competitionsToShow.filter((element) => {
          return element !== value;
        });

        setCompetitionsToShow(uncheckedValue);
        setCompetitionsSelectedAll(false);
      }
    }
  };

  const applyfilters = async () => {
    setAlertError("");
    setIsLoading(true);

    await axios({
      method: "post",
      url: "/api/rsp_line_graph",
      data: JSON.stringify({
        city: citiesToShow,
        customer: customersToShow,
        category: categoriesToShow,
        comp: competitionsToShow,
        sku: skuToShow,
        month: monthsToShow,
        year: selectedYear,
      }),
    })
      .then((response) => {
        if (response?.request?.status === 200) {
          setIsLoading(false);
          setLineGraphdata(response?.data);
          console.log(response, "line graph reonse");
        }
      })
      .catch((error) => {
        console.log("Filter Arrays Api: ", error);
        setIsLoading(false);
        setAlertError("There is a problem in the server. Please contact site admin.");
      });
  };

  const handleInputChange = (event, flag) => {
    let userInput = event?.target?.value;
    // userInput = userInput?.trim();

    setSkuSearchInput(userInput);

    if (userInput) {
      const result = skuFiltersList?.filter((search) =>
        search?.toLowerCase().includes(userInput?.toLowerCase())
      );
      if (result) {
        setSkuFilters(result);
      } else {
        setSkuFilters(skuFiltersList);
      }
    } else {
      setSkuFilters(skuFiltersList);
    }
  };
  return (
    <div className="container-fluid monitor_performance position-relative">
      <div className="d-flex spinner_center">
        <h2 style={{ marginBottom: "1em" }}>RSP Tracker</h2>
        {isLoading && (
          <div className="text-center">
            <div
              className="spinner-border text-info"
              role="status"
              style={{ zIndex: "2" }}
            ></div>
            <br />
            <span className="text-info d-block">Loading...</span>
          </div>
        )}
      </div>
      <div className="media-body">
        {alertError && <Alert variant="danger">{alertError}</Alert>}
        <div className="row content_main_row">
          <div
            className="filters_right w-100 justify-content-center"
            style={{ gap: "5px" }}
          >
            <div className="filter-dropdowns">
              <Dropdown
                className={`${
                  !selectedYear ? "dropDown_disabled" : "dropdown_selected"
                }`}
              >
                <Dropdown.Toggle variant="secondary">
                  {selectedYear ? selectedYear : "Year"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {yearFilters?.map((year, index) => (
                    <Dropdown.Item
                      key={index}
                      onClick={(event) => setSelectedYear(event.target.text)}
                    >
                      {year}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="filter-dropdowns">
              <Dropdown
                className={`${
                  !(monthsToShow.length > 0)
                    ? "dropDown_disabled"
                    : "dropdown_selected"
                }`}
              >
                <Dropdown.Toggle variant="secondary">Month</Dropdown.Toggle>
                <Dropdown.Menu>
                  <div
                    className="p-3 d-flex flex-column"
                    style={{ gap: "5px" }}
                  >
                    <div className="d-flex" style={{ gap: "0 8px" }}>
                      <input
                        type="checkbox"
                        name="selectAll"
                        checked={monthSelectedAll}
                        onChange={(e) => selectAll(e, "month")}
                      />
                      <label htmlFor="selectAll">Select All</label>
                    </div>

                    {monthFilters?.map((month, index) => (
                      <div
                        key={index}
                        className="d-flex"
                        style={{ gap: "0 10px" }}
                      >
                        <input
                          type="checkbox"
                          value={month}
                          onChange={(e) => checkBoxFilterHandle(e, "month")}
                          checked={monthsToShow.includes(month) ? true : false}
                        />
                        <label style={{ fontSize: "12px" }}>{month}</label>
                      </div>
                    ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
              {/* <Dropdown
                className={`${
                  !selectedMonth ? "dropDown_disabled" : "dropdown_selected"
                }`}
              >
                <Dropdown.Toggle variant="secondary">
                  {selectedMonth ? selectedMonth : "Month"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {monthFilters?.map((month, index) => (
                    <Dropdown.Item
                      key={index}
                      onClick={(event) => setSelectedMonth(event.target.text)}
                    >
                      {month}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown> */}
            </div>
            <div className="filter-dropdowns">
              <Dropdown
                className={`${
                  !(citiesToShow.length > 0)
                    ? "dropDown_disabled"
                    : "dropdown_selected"
                }`}
              >
                <Dropdown.Toggle variant="secondary">City</Dropdown.Toggle>
                <Dropdown.Menu>
                  <div
                    className="p-3 d-flex flex-column"
                    style={{ gap: "5px" }}
                  >
                    <div className="d-flex" style={{ gap: "0 8px" }}>
                      <input
                        type="checkbox"
                        name="selectAll"
                        checked={citySelectedAll}
                        onChange={(e) => selectAll(e, "city")}
                      />
                      <label htmlFor="selectAll">Select All</label>
                    </div>

                    {cityFilters?.map((city, index) => (
                      <div
                        key={index}
                        className="d-flex"
                        style={{ gap: "0 10px" }}
                      >
                        <input
                          type="checkbox"
                          value={city}
                          onChange={(e) => checkBoxFilterHandle(e, "city")}
                          checked={citiesToShow.includes(city) ? true : false}
                        />
                        <label style={{ fontSize: "12px" }}>{city}</label>
                      </div>
                    ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="filter-dropdowns">
              <Dropdown
                className={`${
                  !(customersToShow.length > 0)
                    ? "dropDown_disabled"
                    : "dropdown_selected"
                }`}
              >
                <Dropdown.Toggle variant="secondary">Customer</Dropdown.Toggle>
                <Dropdown.Menu>
                  <div
                    className="p-3 d-flex flex-column"
                    style={{ gap: "5px" }}
                  >
                    <div className="d-flex" style={{ gap: "0 8px" }}>
                      <input
                        type="checkbox"
                        name="selectAll"
                        checked={customerSelectedAll}
                        onChange={(e) => selectAll(e, "customer")}
                      />
                      <label htmlFor="selectAll">Select All</label>
                    </div>

                    {customerFilters?.map((customer, index) => (
                      <div
                        key={index}
                        className="d-flex"
                        style={{ gap: "0 10px" }}
                      >
                        <input
                          type="checkbox"
                          value={customer}
                          onChange={(e) => checkBoxFilterHandle(e, "customer")}
                          checked={
                            customersToShow.includes(customer) ? true : false
                          }
                        />
                        <label style={{ fontSize: "12px" }}>{customer}</label>
                      </div>
                    ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="filter-dropdowns">
              <Dropdown
                className={`${
                  !(categoriesToShow.length > 0)
                    ? "dropDown_disabled"
                    : "dropdown_selected"
                }`}
              >
                <Dropdown.Toggle variant="secondary">Category</Dropdown.Toggle>
                <Dropdown.Menu>
                  <div
                    className="p-3 d-flex flex-column"
                    style={{ gap: "5px" }}
                  >
                    <div className="d-flex" style={{ gap: "0 8px" }}>
                      <input
                        type="checkbox"
                        name="selectAll"
                        checked={categorySelectedAll}
                        onChange={(e) => selectAll(e, "category")}
                      />
                      <label htmlFor="selectAll">Select All</label>
                    </div>

                    {categoryFilters?.map((category, index) => (
                      <div
                        key={index}
                        className="d-flex"
                        style={{ gap: "0 10px" }}
                      >
                        <input
                          type="checkbox"
                          value={category}
                          onChange={(e) => checkBoxFilterHandle(e, "category")}
                          checked={
                            categoriesToShow.includes(category) ? true : false
                          }
                        />
                        <label style={{ fontSize: "12px" }}>{category}</label>
                      </div>
                    ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="filter-dropdowns">
              <Dropdown
                className={`${
                  !(competitionsToShow.length > 0)
                    ? "dropDown_disabled"
                    : "dropdown_selected"
                }`}
              >
                <Dropdown.Toggle variant="secondary">
                  Competitions
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div
                    className="p-3 d-flex flex-column"
                    style={{ gap: "5px" }}
                  >
                    <div className="d-flex" style={{ gap: "0 8px" }}>
                      <input
                        type="checkbox"
                        name="selectAll"
                        checked={regionSelectedAll}
                        onChange={(e) => selectAll(e, "competition")}
                      />
                      <label htmlFor="selectAll">Select All</label>
                    </div>

                    {competitionFilters?.map((region, index) => (
                      <div
                        key={index}
                        className="d-flex"
                        style={{ gap: "0 10px" }}
                      >
                        <input
                          type="checkbox"
                          value={region}
                          onChange={(e) =>
                            checkBoxFilterHandle(e, "competition")
                          }
                          checked={
                            competitionsToShow.includes(region) ? true : false
                          }
                        />
                        <label style={{ fontSize: "12px" }}>{region}</label>
                      </div>
                    ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div className="filter-dropdowns">
              <Dropdown
                className={`${
                  !(skuToShow.length > 0)
                    ? "dropDown_disabled"
                    : "dropdown_selected"
                }`}
              >
                <Dropdown.Toggle variant="secondary">SKU</Dropdown.Toggle>
                <Dropdown.Menu>
                  <div
                    className="col-md-9 pt-3"
                    style={{ height: "410px", width: "200px" }}
                  >
                    <input
                      type="search"
                      className="form-control m-auto"
                      placeholder="Search Sku"
                      defaultValue={skuSearchInput}
                      onChange={(event) => handleInputChange(event)}
                      autoComplete="off"
                      required
                      style={{ width: "90%" }}
                    />

                    <div
                      className="p-3 d-flex flex-column"
                      style={{ gap: "5px" }}
                    >
                      <div className="d-flex" style={{ gap: "0 8px" }}>
                        <input
                          type="checkbox"
                          name="selectAll"
                          checked={skuSelectedAll}
                          onChange={(e) => selectAll(e, "sku")}
                        />
                        <label htmlFor="selectAll">Select All</label>
                      </div>

                      {!skuFilters ? (
                        <div className="d-flex justify-content-center">
                          <br />
                          <div
                            className="spinner-border text-info"
                            role="status"
                          ></div>
                        </div>
                      ) : (
                        <div className="mt-3">
                          {skuFilters?.map((sku, index) => (
                            <div
                              key={index}
                              className="d-flex"
                              style={{ gap: "0 10px" }}
                            >
                              <input
                                type="checkbox"
                                value={sku}
                                onChange={(e) => checkBoxFilterHandle(e, "sku")}
                                checked={skuToShow.includes(sku) ? true : false}
                              />
                              <label style={{ fontSize: "12px" }}>{sku}</label>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <button
              className="btn btn-success"
              disabled={
                citiesToShow.length < 1 ||
                customersToShow.length < 1 ||
                categoriesToShow.length < 1 ||
                competitionsToShow.length < 1 ||
                skuToShow.length < 1
              }
              onClick={applyfilters}
            >
              Filter
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8 my_graph">
            {lineGraphdata && (
              <LineChart
                loading={lineGraphdata?.length < 1}
                lineGraphData={lineGraphdata?.data}
                weeks={lineGraphdata?.month}
                colors={lineGraphdata?.color}
                valuesToShow={lineGraphdata?.values_to_show}
                customHeight={450}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RSP;
