import { LogLevel } from '@azure/msal-browser';

const clientId = process.env.REACT_APP_AZURE_AD_CLIENT_ID;
const tenantId = process.env.REACT_APP_AZURE_AD_TENANT_ID;

export const msalConfig = 
{
    auth: 
    {
        clientId: clientId,
        authority: `https://login.microsoftonline.com/${tenantId}`,
        redirectUri: "/login",
        postLogoutRedirectUri: "/login",
        validateAuthority: true,
        navigateToLoginRequestUrl: true,
        clientCapabilities: ['CP1']
    },
    cache: 
    {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false
    },
    system: 
    {
        loggerOptions: 
        {
            loggerCallback: (level, message, containsPii) => 
            {
                if (containsPii) 
                {
                    return;
                }

                switch (level) 
                {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        console.log(message);
                        return;
                }
            }
        }
    }
};