import axios from "axios";
import React, { useState, useEffect } from "react";
import { Alert, Dropdown, Table } from "react-bootstrap";
import CustomDataMap from "../../components/CustomDataMap";
import DoubleBarChart from "../../components/DoubleBarChart";
import "../../styles/monitorAnalytics.scss";
import LogUserActivity from "../../components/LogUserActivity";

const Summary = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [alertError, setAlertError] = useState(false);
 const [logId, setLogId] = useState(null);
  // FILTER DROPDOWN  OPTIONS
  const [yearFilters, setYearFilters] = useState([]);
  const [monthFilters, setMonthFilters] = useState([]);
  const [waveFilters, setWaveFilters] = useState(["wave1", "wave2"]);
  // SELECTED FILTER VALUE
  const [selectedMonth, setSelectedMonth] = useState("January");
  const [selectedYear, setSelectedYear] = useState("FY2223");
  const [selectedWave, setSelectedWave] = useState("");

  // REGIONS DATA
  const [sosRegionData, setSosRegionData] = useState([]);
  const [osaRegionData, setOsaRegionData] = useState([]);
  const [planogramRegionData, setPlanogramRegionData] = useState([]);

  /* CHART DATASET VARIABLES */
  const [onShelfSummaryByCustomer, setOnShelfSummaryByCustomer] = useState("");
  const [planogramSummaryByCustomer, setPlanogramSummaryByCustomer] =
    useState("");
  const [sosSummaryByCustomer, setSosSummaryByCustomer] = useState("");

  useEffect(() => {
    setIsLoading(true);
    const getWaveFilterValues = async () => {
      await axios({
        method: "post",
        url: "/api/filter_values_summary",
      })
        .then((response) => {
          if (response?.request?.status === 200) {
            // setYearFilters(response?.data?.year);
            // setMonthFilters(response?.data?.month);
            setWaveFilters(response?.data?.wawe);
            console.log(response, "filter values");
          }
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };
    getWaveFilterValues();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const getFilterValues = async () => {
      await axios({
        method: "post",
        url: "/api/filter_values_on_shelf",
      })
        .then((response) => {
          setIsLoading(false);
          const responseData = response?.data;
          console.log(responseData, "month filters");
          setMonthFilters(responseData.month);
          setYearFilters(responseData?.year);
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };
    LogUserActivity("Summary Activity Started", props?.currentUser?.user, setLogId, setAlertError);
    getFilterValues();
  }, []);

  useEffect(() => {
    setAlertError("");
    setPlanogramSummaryByCustomer(null);
    setOnShelfSummaryByCustomer(null);
    setSosSummaryByCustomer(null);

    const getOnShelfSummaryByCustomer = async () => {
      await axios({
        method: "post",
        url: "/api/on_shelf_summary_availbility_customer",
        data: JSON.stringify({
          year: "",
          month: "",
          wave: "",
        }),
      })
        .then((response) => {
          setIsLoading(false);
          setOnShelfSummaryByCustomer(response?.data?.data);
          console.log(response, " new reposne");
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };

    const getSOS_SummaryByCustomer = async () => {
      await axios({
        method: "post",
        url: "/api/SOS_summary_availbility_customer",
        data: JSON.stringify({
          year: "",
          month: "",
          wave: "",
        }),
      })
        .then((response) => {
          setIsLoading(false);
          setSosSummaryByCustomer(response?.data?.data);
          // setIsLoading(false);
          // console.log(response, 'month  total')
          // if (response?.data?.data[0]?.result) {

          //     setAvailabilityPercentage(response?.data?.data[0])
          // } else {
          //     setAvailabilityPercentage('No Data found')
          // }
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };

    const getPlanogram_SummaryByCustomer = async () => {
      await axios({
        method: "post",
        url: "/api/summary_planogram_availbility_customer",
        data: JSON.stringify({
          year: "",
          month: "",
          wave: "",
        }),
      })
        .then((response) => {
          setIsLoading(false);
          setPlanogramSummaryByCustomer(response?.data?.data);
          // setIsLoading(false);
          // console.log(response, 'month  total')
          // if (response?.data?.data[0]?.result) {

          //     setAvailabilityPercentage(response?.data?.data[0])
          // } else {
          //     setAvailabilityPercentage('No Data found')
          // }
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };

    const getSosMapData = async () => {
      await axios({
        method: "post",
        url: "/api/region_SOS_availbility_city",
        data: JSON.stringify({
          category: [],
          month: "January",
          city: [],
          customer: [],
          region: [],
          year: selectedYear,
        }),
      })
        .then((response) => {
          if (response?.request?.status === 200) {
            setSosRegionData(response?.data?.data);
            console.log(response, "regions");
          }
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };
    const getOsaMapData = async () => {
      await axios({
        method: "post",
        url: "/api/region_on_shelf_availbility_city",
        data: JSON.stringify({
          category: [],
          month: "January",
          city: [],
          customer: [],
          region: [],
          year: selectedYear,
        }),
      })
        .then((response) => {
          if (response?.request?.status === 200) {
            setOsaRegionData(response?.data?.data);
            console.log(response, "regions");
          }
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };
    const getPlanogramMapData = async () => {
      await axios({
        method: "post",
        url: "/api/region_planogram_availbility_city",
        data: JSON.stringify({
          category: [],
          month: "January",
          city: [],
          customer: [],
          region: [],
          year: selectedYear,
        }),
      })
        .then((response) => {
          if (response?.request?.status === 200) {
            setPlanogramRegionData(response?.data?.data);
            console.log(response, "regions");
          }
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };
    getSosMapData();
    getOsaMapData();
    getPlanogramMapData();
    getSOS_SummaryByCustomer();
    getPlanogram_SummaryByCustomer();
    getOnShelfSummaryByCustomer();
  }, []);

  const applyfilters = () => {
    setAlertError("");
    setIsLoading(true);
    const getPlanogram_SummaryByCustomer = async () => {
      await axios({
        method: "post",
        url: "/api/summary_planogram_availbility_customer",
        data: JSON.stringify({
          year: selectedYear,
          month: selectedMonth,
          wave: "",
        }),
      })
        .then((response) => {
          setIsLoading(false);
          setPlanogramSummaryByCustomer(response?.data?.data);
          console.log(response, " new reposne");
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };

    const getOnShelfSummaryByCustomer = async () => {
      await axios({
        method: "post",
        url: "/api/on_shelf_summary_availbility_customer",
        data: JSON.stringify({
          year: selectedYear,
          month: selectedMonth,
          wave: "",
        }),
      })
        .then((response) => {
          setIsLoading(false);
          setOnShelfSummaryByCustomer(response?.data?.data);
          console.log(response, " new reposne");
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };

    const getSOS_SummaryByCustomer = async () => {
      await axios({
        method: "post",
        url: "/api/SOS_summary_availbility_customer",
        data: JSON.stringify({
          year: selectedYear,
          month: selectedMonth,
          wave: "",
        }),
      })
        .then((response) => {
          setIsLoading(false);
          setSosSummaryByCustomer(response?.data?.data);
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };

    const getSosMapData = async () => {
      await axios({
        method: "post",
        url: "/api/region_SOS_availbility_city",
        data: JSON.stringify({
          category: [],
          month: selectedMonth,
          city: [],
          customer: [],
          region: [],
          year: selectedYear,
        }),
      })
        .then((response) => {
          if (response?.request?.status === 200) {
            setSosRegionData(response?.data?.data);
            console.log(response, "regions");
          }
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };
    const getOsaMapData = async () => {
      await axios({
        method: "post",
        url: "/api/region_on_shelf_availbility_city",
        data: JSON.stringify({
          category: [],
          month: selectedMonth,
          city: [],
          customer: [],
          region: [],
          year: selectedYear,
        }),
      })
        .then((response) => {
          if (response?.request?.status === 200) {
            setOsaRegionData(response?.data?.data);
            console.log(response, "regions");
          }
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };
    const getPlanogramMapData = async () => {
      await axios({
        method: "post",
        url: "/api/region_planogram_availbility_city",
        data: JSON.stringify({
          category: [],
          month: selectedMonth,
          city: [],
          customer: [],
          region: [],
          year: selectedYear,
        }),
      })
        .then((response) => {
          if (response?.request?.status === 200) {
            setPlanogramRegionData(response?.data?.data);
            console.log(response, "regions");
          }
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };
    getSosMapData();
    getOsaMapData();
    getPlanogramMapData();
    getSOS_SummaryByCustomer();
    getPlanogram_SummaryByCustomer();
    getOnShelfSummaryByCustomer();
  };

  return (
    <div className="container-fluid monitor_performance position-relative">
      <h2 className="heading_sm_center">Summary</h2>

      <div className="media-body">
        {alertError && <Alert variant="danger">{alertError}</Alert>}
        <div className="row content_main_row">
          <div
            className="filters_right w-100 justify-content-center"
            style={{ gap: "5px" }}
          >
            <div className="filter-dropdowns">
              <Dropdown
                className={`${
                  !selectedYear ? "dropDown_disabled" : "dropdown_selected"
                }`}
              >
                <Dropdown.Toggle variant="secondary">
                  {selectedYear ? selectedYear : "Year"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {yearFilters?.map((year, index) => (
                    <Dropdown.Item
                      key={index}
                      onClick={(event) => setSelectedYear(event.target.text)}
                    >
                      {year}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="filter-dropdowns">
              <Dropdown
                className={`${
                  !selectedMonth ? "dropDown_disabled" : "dropdown_selected"
                }`}
              >
                <Dropdown.Toggle variant="secondary">
                  {selectedMonth ? selectedMonth : "Month"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {monthFilters?.map((month, index) => (
                    <Dropdown.Item
                      key={index}
                      onClick={(event) => setSelectedMonth(event.target.text)}
                    >
                      {month}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {/* <div className="filter-dropdowns">
                            <Dropdown className={`${!(selectedWave) ? 'dropDown_disabled' : 'dropdown_selected'}`}>
                                <Dropdown.Toggle variant="secondary">
                                    {selectedWave ? selectedWave : 'Wave'}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {waveFilters?.map((wave, index) =>
                                        <Dropdown.Item key={index} onClick={(event) => setSelectedWave(event.target.text)}>{wave}</Dropdown.Item>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div> */}
              <button className="btn btn-success" onClick={applyfilters}>
              Filter
              </button>
          </div>
        </div>
        <div className="row justify-content-between">
          <div className="col-8 d-flex align-items-center justify-content-center">
            {isLoading && (
              <div className="text-center">
                <div
                  className="spinner-border text-info"
                  role="status"
                  style={{ zIndex: "2" }}
                ></div>
                <br />
                <span className="text-info d-block">Loading...</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <label htmlFor="" className="table_label text-center my-0">
            OnShelf Availability By City (%)
          </label>
          <CustomDataMap regionData={osaRegionData} summaryPage={true} />
        </div>
        <div className="col-md-4">
          <label htmlFor="" className="table_label text-center my-0">
            Planogram By City (%)
          </label>
          <CustomDataMap regionData={planogramRegionData} summaryPage={true} />
        </div>
        <div className="col-md-4">
          <label htmlFor="" className="table_label text-center my-0">
            SOS By City (%)
          </label>
          <CustomDataMap regionData={sosRegionData} summaryPage={true} />
        </div>
      </div>
      <div className="row gap-3">
        <div className="col-md-5">
          <div className="row w-100 flex-column align-items-center">
            <div className="col-12 my_graph">
              <DoubleBarChart
                dataset={onShelfSummaryByCustomer}
                label={"OnShelf Availability By Customer (%)"}
                loading={onShelfSummaryByCustomer ? false : true}
              />
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <div className="row w-100 flex-column align-items-center">
            <div className="col-12 my_graph">
              <DoubleBarChart
                dataset={planogramSummaryByCustomer}
                label={"Planogram By Customer (%)"}
                loading={planogramSummaryByCustomer ? false : true}
              />
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <div className="row w-100 flex-column align-items-center">
            <div className="col-12 my_graph">
              <DoubleBarChart
                dataset={sosSummaryByCustomer}
                label={"SOS By Customer (%)"}
                loading={sosSummaryByCustomer ? false : true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
