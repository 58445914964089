// NotificationList.js
import React, { useEffect, useState } from 'react';
import { useNotification } from './NotificationProvider';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported

const NotificationList = ({ onClose }) => {
  const { notifications } = useNotification(); // Access the global notification context
  const [mouseMoved, setMouseMoved] = useState(false);
  const [lastMouseMoveTime, setLastMouseMoveTime] = useState(Date.now());

  useEffect(() => {
    let timer;

    const handleMouseMove = () => {
      setMouseMoved(true);
      setLastMouseMoveTime(Date.now());
    };

    const checkMouseActivity = () => {
      const currentTime = Date.now();
      if (currentTime - lastMouseMoveTime > 3000) {
        setMouseMoved(false);
      }
    };

    document.addEventListener('mousemove', handleMouseMove);

    timer = setInterval(() => {
      checkMouseActivity();
      if (!mouseMoved) {
        onClose();
      }
    }, 1000);

    return () => {
      clearInterval(timer);
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [mouseMoved, lastMouseMoveTime, onClose]);

  return (
    <div className="position-absolute top-50 end-0 p-3" style={{ zIndex: 1000 }}>
    <div className="card shadow-sm">
      <div className="card-header d-flex justify-content-between align-items-center">
        <h5 className="mb-0">Notifications</h5>
        <button onClick={onClose} className="btn-close" aria-label="Close"></button>
      </div>
      <div className="card-body" style={{ maxHeight: '200px', overflowY: 'auto' }}>
        <ul className="list-group">
          {notifications?.length > 0 ? (
            notifications.map((notification) => (
              <li key={notification.id} className="list-group-item">
                <strong>Task ID: {notification.taskId}</strong> - {notification.message}
              </li>
            ))
          ) : (
            <li className="list-group-item">No notifications</li>
          )}
        </ul>
      </div>
    </div>
  </div>
  );
};

export default NotificationList;
