import { React, useState } from 'react';
import { Navigate, useNavigate, Link, useLocation } from 'react-router-dom';
import { Alert, Button } from 'react-bootstrap';
import axios from 'axios';
import Logo from '../../assets/clorox.png';
import '../../styles/login.css';

const ChangePassword = ({ currentUser }) => 
{
    const [isLoading, setIsLoading] = useState(false);
    const [alertError, setAlertError] = useState(null);
    const [password, setPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();

    const handlePasswordChange = (event) =>
    {
        setPassword(event.target.value);
    }

    const handleConfirmPasswordChange = (event) => 
    {
        if (typeof event.target.value !== "undefined" && event.target.value !== "") 
        {
            if (password !== event.target.value) 
            {
                event.target.setCustomValidity("Passwords do not match.");
            }
            else
            {
                event.target.setCustomValidity("");
            }

            setConfirmPassword(event.target.value);
        }
    }

    const handleSubmit = async (event) => 
    {
        event.preventDefault();
            
        setIsLoading(true);
        setAlertError(null);

        await axios({
            method: "post",
            url: "/api/password_change",
            data: { token: location?.pathname?.split("/")[4], password: confirmPassword }
        })
        .then((response) =>
        {
            setIsLoading(false);

            if (response.status === 200)
            {
                navigate("/login", { replace: false });
            }
            else
            {
                setAlertError(response.message);
            }
        })
        .catch((error) =>
        {
            console.log("Change Password Api: ", error);
            setIsLoading(false);
            setAlertError("There is a problem in the server. Please contact site admin.");
        });
    }

    return (
        <>
            {currentUser?.user ? (
                <Navigate to = {"/"} replace = {true} />
            ) : (
                <div className = "loginContainer">
                    <div className = "row">
                        <div className = "col-md-7">
                            <div className = "login">
                                <img src = {Logo} alt = "Clorox" />
                                <br />
                                
                                <h1>Change Password</h1>
                                <br />

                                {isLoading && (
                                    <div className = "ui active dimmer Loader">
                                        <div className = "ui loader"></div>
                                    </div>
                                )}

                                {alertError && (
                                    <Alert variant = "danger">{alertError}</Alert>
                                )}

                                <form onSubmit = {handleSubmit}>
                                    <div className = "form-group">
                                        <input type = "password" name = "password" className = "form-control" id = "password" placeholder = "Password" onChange = {handlePasswordChange} required/>
                                    </div>
                                    <div className = "form-group password-container">
                                        <input type = "password" name = "confirm-password" className = "form-control" id = "confirm-password" placeholder = "Change Password" onChange = {handleConfirmPasswordChange} required/>
                                    </div>

                                    <div className = "d-flex justify-content-between align-items-center reset-password">
                                        <Link to = "/login">Back To Login</Link>
                                        <Button type = "submit" variant = "primary" className = "login-button">Save Password</Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ChangePassword;