import axios from "axios";
import React, { useState, useEffect } from "react";
import { Alert, Button, Dropdown, Modal, Table } from "react-bootstrap";
import { BsBookshelf } from "react-icons/bs";
import CustomDataMap from "../../components/CustomDataMap";
import DoubleBarChart from "../../components/DoubleBarChart";
import DynamicDataTable from "../../components/DynamicDataTable";
import "../../styles/monitorAnalytics.scss";
import LogUserActivity from "../../components/LogUserActivity";

const Planogram = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [alertError, setAlertError] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertModal, setAlertModal] = useState(null);
  const [logId, setLogId] = useState(null);

  const [csvData, setCSVData] = useState();
  const [buttonClicked, setButtonClicked] = useState(false);
  // FILTER DROPDOWN  OPTIONS
  const [regionFilters, setRegionFilters] = useState([]);
  const [monthFilters, setMonthFilters] = useState([]);
  const [categoryFilters, setCategoryFilters] = useState([]);
  const [customerFilters, setCustomerFilters] = useState([]);
  const [cityFilters, setCityFilters] = useState([]);
  const [yearFilters, setYearFilters] = useState([]);
  const [isFirstTime, setIsFirstTime] = useState(true);

  // SELECTED FILTER VALUE
  const [availabilityPercentage, setAvailabilityPercentage] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [categorySelectedAll, setCategorySelectedAll] = useState(false);
  const [customerSelectedAll, setCustomerSelectedAll] = useState(false);
  const [citySelectedAll, setCitySelectedAll] = useState(false);
  const [regionSelectedAll, setRegionSelectedAll] = useState(false);

  // TABLE DATA
  const [planogramWaveTableLoading, setPlanogramWaveTableLoading] =
    useState(false);
  const [planogramWaveColumnDetails, setPlanogramWaveColumnDetails] =
    useState();
  const [planogramWaveRows, setPlanogramWaveRows] = useState([]);
  const [planogramWaveColumnHeaders, setPlanogramWaveColumnHeaders] = useState(
    []
  );
  const [planogramWavePage, setPlanogramWavePage] = React.useState(0);
  const [planogramWavePageSize, setPlanogramWavePageSize] = React.useState(25);
  const [planogramWaveRowCount, setPlanogramWaveRowCount] = React.useState();

  /* CHART DATASET VARIABLES */
  const [categoryDataset, setCategoryDataset] = useState("");
  const [customerDataset, setCustomerDataset] = useState("");
  const [monthDataset, setMonthDataset] = useState("");
  const [cityDataset, setCityDataset] = useState("");
  const [tableData, setTableData] = useState(null);

  // REGIONS DATA
  const [regionData, setRegionData] = useState([]);
  // FILTERS ARRAYS
  const [customersToShow, setCustomersToShow] = useState([]);
  const [citiesToShow, setCitiesToShow] = useState([]);
  const [regionsToShow, setRegionsToShow] = useState([]);
  const [categoriesToShow, setCategoriesToShow] = useState([]);
  const timeOut = () => {
    const timeId = setTimeout(() => {
      setAlertSuccess(null);
      setAlertError(null);
    }, 2000);

    return () => {
      clearTimeout(timeId);
    };
  };
  useEffect(() => {
    const currentMonth = new Date().getMonth(); // Adding 1 because getMonth() returns a zero-based index
    const currentYear = new Date().getFullYear();
    let fiscalYearStartYear;
  
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
  
    fiscalYearStartYear = currentMonth >= 6 ? currentYear : currentYear - 1;
  
    const fiscalYearLabel = `FY${fiscalYearStartYear.toString().slice(-2)}${(
      fiscalYearStartYear + 1
    )
      .toString()
      .slice(-2)}`;
  
    setSelectedYear(fiscalYearLabel);
    setSelectedMonth(monthNames[currentMonth]);
    setIsFirstTime(true);
  }, []);

  useEffect(() => {
    const tempWaveColumnDetails = {};
    if (planogramWaveColumnHeaders) {
      planogramWaveColumnHeaders
        .filter(function (column) {
          if (column === "ID") {
            return false; // skip
          }
          return true;
        })
        .map((header) => {
          if (isNaN(header)) {
            tempWaveColumnDetails[header] = { type: "string", flex: 1 };
          } else {
            tempWaveColumnDetails[header] = { type: "number", flex: 1 };
          }
        });
      setPlanogramWaveColumnDetails(tempWaveColumnDetails);
    }
  }, [planogramWaveColumnHeaders]);

  // useEffect(() => {
  //     const fetchTableData = async () => {
  //         setPlanogramWaveTableLoading(true)
  //         await axios({
  //             method: "post",
  //             url: "/api/planogram_wave",
  //             headers: {
  //                 "Access-Control-Allow-Origin": "*",
  //                 'Content-Type': 'application/json'
  //             },
  //             data: JSON.stringify({
  //                 month: selectedMonth ? selectedMonth : 'January',
  //                 city: citiesToShow,
  //                 customer: customersToShow,
  //                 limit: planogramWavePageSize,
  //                 skip: planogramWavePage
  //             })
  //         })
  //             .then((response) => {
  //                 console.log(response, ' table response')
  //                 setPlanogramWaveTableLoading(false)
  //                 const responseData = response?.data;
  //                 setPlanogramWaveRows(responseData?.table)
  //                 setPlanogramWaveRowCount(responseData?.rowCount)
  //                 setPlanogramWaveColumnHeaders(responseData?.columns)
  //             })
  //             .catch((error) => {
  //                 setPlanogramWaveTableLoading(false)
  //                 setAlertError('something went wrong')
  //                 console.log("Get Filters Api: ", error);
  //             });
  //     }
  //     fetchTableData();
  // }, [planogramWavePage, planogramWavePageSize, customersToShow, citiesToShow, selectedMonth])

  useEffect(() => {
    setIsLoading(true);
    const getFilterValues = async () => {
      await axios({
        method: "post",
        url: "/api/filter_values_on_shelf",
      })
        .then((response) => {
          if (response?.request?.status === 200) {
            setRegionFilters(response?.data?.region);
            setMonthFilters(response?.data?.month);
            setCategoryFilters(response?.data?.category);
            setCustomerFilters(response?.data?.customer);
            setCityFilters(response?.data?.city);
            setYearFilters(response?.data?.year);
            console.log(response, "filter values");
          }
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };
    LogUserActivity(
      "Planogram Activity Started",
      props?.currentUser?.user,
      setLogId,
      setAlertError
    );
    getFilterValues();
  }, []);
  useEffect(() => {
    setAlertError("");
    setCustomerDataset(null);
    setCategoryDataset(null);
    setCityDataset(null);
    const getCustomerBarChartData = async () => {
      await axios({
        method: "post",
        url: "/api/planogram_availbility_customer",
        data: JSON.stringify({
          category: [],
          month: selectedMonth,
          city: [],
          customer: [],
          region: [],
          year: selectedYear,
        }),
      })
        .then((response) => {
          setIsLoading(false);
          setCustomerDataset(response?.data?.data);
          console.log(response, " new reposne");
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };

    const getCategoryBarChartData = async () => {
      await axios({
        method: "post",
        url: "/api/planogram_availbility_category",
        data: JSON.stringify({
          category: [],
          month: selectedMonth,
          city: [],
          customer: [],
          region: [],
          year: selectedYear,
        }),
      })
        .then((response) => {
          setIsLoading(false);
          setCategoryDataset(response?.data?.data);
          console.log(response, " new reposne");
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };

    const getCityBarChartData = async () => {
      await axios({
        method: "post",
        url: "/api/planogram_availbility_city",
        data: JSON.stringify({
          category: [],
          month: selectedMonth,
          city: [],
          customer: [],
          region: [],
          year: selectedYear,
        }),
      })
        .then((response) => {
          setIsLoading(false);
          setCityDataset(response?.data?.data);
          console.log(response, " new reposne");
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };

    const getTableData = async () => {
      await axios({
        method: "post",
        url: "/api/planogram_availbility_customer_category",
        data: JSON.stringify({
          category: [],
          month: selectedMonth,
          city: [],
          customer: [],
          region: [],
          year: selectedYear,
        }),
      })
        .then((response) => {
          setTableData(response?.data);
          console.log(response, " new table");
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };
    const getAvailabilityTotal = async () => {
      await axios({
        method: "post",
        url: "/api/planogram_availbility_total",
        data: JSON.stringify({
          category: [],
          city: [],
          customer: [],
          region: [],
          year: selectedYear,
        }),
      })
        .then((response) => {
          setIsLoading(false);
          console.log(response, "resssss");
          setMonthDataset(response?.data?.data);
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAvailabilityPercentage(" ");
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };

    const getAvailabilityPercentage = async () => {
      await axios({
        method: "post",
        url: "/api/planogram_availbility_percent_total",
        data: JSON.stringify({
          category: [],
          month: selectedMonth,
          city: [],
          customer: [],
          region: [],
          year: selectedYear,
        }),
      })
        .then((response) => {
          setIsLoading(false);
          console.log(response, "month  total");
          if (response?.data?.data[0]?.result) {
            setAvailabilityPercentage(response?.data?.data[0]);
          } else {
            setAvailabilityPercentage("No Data found");
          }
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAvailabilityPercentage(" ");
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };

    const getMapData = async () => {
      await axios({
        method: "post",
        url: "/api/region_planogram_availbility_city",
        data: JSON.stringify({
          category: [],
          month: selectedMonth,
          city: [],
          customer: [],
          region: [],
          year: selectedYear,
        }),
      })
        .then((response) => {
          if (response?.request?.status === 200) {
            setRegionData(response?.data?.data);
            console.log(response, "regions");
          }
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };
    if (selectedYear && selectedMonth && isFirstTime) {
      setIsFirstTime(false);
      getAvailabilityTotal();
      getAvailabilityPercentage();
      getCustomerBarChartData();
      getCategoryBarChartData();
      getCityBarChartData();
      getTableData();
      getMapData();
    }
  }, [selectedYear, selectedMonth]);

  const selectAll = (e, flag) => {
    if (flag === "category") {
      if (!e.target.checked) {
        setCategoriesToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          categoryFilters.map((category) => {
            return category;
          })
        );

        setCategoriesToShow(filterArray[0]);
      }

      setCategorySelectedAll(e.target.checked);
    }

    if (flag === "city") {
      if (!e.target.checked) {
        setCitiesToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          cityFilters.map((city) => {
            return city;
          })
        );

        setCitiesToShow(filterArray[0]);
      }

      setCitySelectedAll(e.target.checked);
    }
    if (flag === "region") {
      if (!e.target.checked) {
        setRegionsToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          regionFilters.map((region) => {
            return region;
          })
        );

        setRegionsToShow(filterArray[0]);
      }

      setRegionSelectedAll(e.target.checked);
    }
    if (flag === "customer") {
      if (!e.target.checked) {
        setCustomersToShow([]);
      } else {
        let filterArray = [];

        filterArray.push(
          customerFilters.map((customer) => {
            return customer;
          })
        );

        setCustomersToShow(filterArray[0]);
      }

      setCustomerSelectedAll(e.target.checked);
    }
  };
  const checkBoxFilterHandle = (e, flag) => {
    const { checked, value } = e.target;

    if (flag === "category") {
      if (checked) {
        const checkedValue = categoriesToShow.includes(value);

        if (!checkedValue) {
          const tempCategoryFilters = [...categoriesToShow, value];
          setCategoriesToShow(tempCategoryFilters);

          if (tempCategoryFilters.length === categoryFilters.length) {
            setCategorySelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = categoriesToShow.filter((element) => {
          return element !== value;
        });

        setCategoriesToShow(uncheckedValue);
        setCategorySelectedAll(false);
      }
    }
    if (flag === "customer") {
      if (checked) {
        const checkedValue = customersToShow.includes(value);

        if (!checkedValue) {
          const tempCustomerFilters = [...customersToShow, value];
          setCustomersToShow(tempCustomerFilters);

          if (tempCustomerFilters.length === customerFilters.length) {
            setCustomerSelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = customersToShow.filter((element) => {
          return element !== value;
        });

        setCustomersToShow(uncheckedValue);
        setCustomerSelectedAll(false);
      }
    }
    if (flag === "city") {
      if (checked) {
        const checkedValue = citiesToShow.includes(value);

        if (!checkedValue) {
          const tempCityFilters = [...citiesToShow, value];
          setCitiesToShow(tempCityFilters);

          if (tempCityFilters.length === cityFilters.length) {
            setCitySelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = citiesToShow.filter((element) => {
          return element !== value;
        });

        setCitiesToShow(uncheckedValue);
        setCitySelectedAll(false);
      }
    }
    if (flag === "region") {
      if (checked) {
        const checkedValue = regionsToShow.includes(value);

        if (!checkedValue) {
          const tempRegionFilters = [...regionsToShow, value];
          setRegionsToShow(tempRegionFilters);

          if (tempRegionFilters.length === regionFilters.length) {
            setRegionSelectedAll(true);
          }
        }
      } else {
        const uncheckedValue = regionsToShow.filter((element) => {
          return element !== value;
        });

        setRegionsToShow(uncheckedValue);
        setRegionSelectedAll(false);
      }
    }
  };

  const applyfilters = () => {
    setAlertError("");
    setIsLoading(true);
    const getCustomerBarChartData = async () => {
      await axios({
        method: "post",
        url: "/api/planogram_availbility_customer",
        data: JSON.stringify({
          category: categoriesToShow,
          month: selectedMonth,
          year: selectedYear,
          city: citiesToShow,
          customer: customersToShow,
          region: regionsToShow,
        }),
      })
        .then((response) => {
          setIsLoading(false);
          setCustomerDataset(response?.data?.data);
          console.log(response, " new reposne");
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };

    const getCategoryBarChartData = async () => {
      await axios({
        method: "post",
        url: "/api/planogram_availbility_category",
        data: JSON.stringify({
          category: categoriesToShow,
          month: selectedMonth,
          year: selectedYear,
          city: citiesToShow,
          customer: customersToShow,
          region: regionsToShow,
        }),
      })
        .then((response) => {
          setIsLoading(false);
          setCategoryDataset(response?.data?.data);
          console.log(response, " new reposne");
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };

    const getCityBarChartData = async () => {
      await axios({
        method: "post",
        url: "/api/planogram_availbility_city",
        data: JSON.stringify({
          category: categoriesToShow,
          month: selectedMonth,
          year: selectedYear,
          city: citiesToShow,
          customer: customersToShow,
          region: regionsToShow,
        }),
      })
        .then((response) => {
          setIsLoading(false);
          setCityDataset(response?.data?.data);
          console.log(response, " new reposne");
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };

    const getTableData = async () => {
      await axios({
        method: "post",
        url: "/api/planogram_availbility_customer_category",
        data: JSON.stringify({
          category: categoriesToShow,
          month: selectedMonth,
          year: selectedYear,
          city: citiesToShow,
          customer: customersToShow,
          region: regionsToShow,
        }),
      })
        .then((response) => {
          setTableData(response?.data);
          console.log(response, " new table");
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };
    const getAvailabilityTotal = async () => {
      await axios({
        method: "post",
        url: "/api/planogram_availbility_total",
        data: JSON.stringify({
          category: categoriesToShow,
          year: selectedYear,
          city: citiesToShow,
          customer: customersToShow,
          region: regionsToShow,
        }),
      })
        .then((response) => {
          setIsLoading(false);
          console.log(response, "resssss");
          setMonthDataset(response?.data?.data);
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAvailabilityPercentage(" ");
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };
    const getAvailabilityPercentage = async () => {
      await axios({
        method: "post",
        url: "/api/planogram_availbility_percent_total",
        data: JSON.stringify({
          category: categoriesToShow,
          month: selectedMonth,
          year: selectedYear,
          city: citiesToShow,
          customer: customersToShow,
          region: regionsToShow,
        }),
      })
        .then((response) => {
          setIsLoading(false);
          console.log(response, "month  total");
          if (response?.data?.data[0]?.result) {
            setAvailabilityPercentage(response?.data?.data[0]);
          } else {
            setAvailabilityPercentage("No Data found");
          }
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAvailabilityPercentage(" ");
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };
    const getMapData = async () => {
      await axios({
        method: "post",
        url: "/api/region_planogram_availbility_city",
        data: JSON.stringify({
          category: categoriesToShow,
          month: selectedMonth,
          year: selectedYear,
          city: citiesToShow,
          customer: customersToShow,
          region: regionsToShow,
        }),
      })
        .then((response) => {
          if (response?.request?.status === 200) {
            setRegionData(response?.data?.data);
            console.log(response, "regions");
          }
        })
        .catch((error) => {
          console.log("Filter Arrays Api: ", error);
          setIsLoading(false);
          setAlertError("There is a problem in the server. Please contact site admin.");
        });
    };

    getAvailabilityTotal();
    getAvailabilityPercentage();
    getCustomerBarChartData();
    getCategoryBarChartData();
    getCityBarChartData();
    getTableData();
    getMapData();
  };
  const emailImages = async (url, name) => {
    setButtonClicked(true);
    await axios({
      method: "post",
      url: url,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      data: {
        category: categoriesToShow,
        month: selectedMonth,
        year: selectedYear,
        city: citiesToShow,
        customer: customersToShow,
        username: props?.currentUser?.user,
        pagename: "Planogram",
      },
      timeout: 300000,
    })
      .then((response) => {
        setButtonClicked(false);
        setAlertModal(response?.data?.data);
        timeOut();
      })
      .catch(function (error) {
        setButtonClicked(false);
        timeOut();
        console.log(error);
      });
  };
  const hideModal = () => {
    setAlertModal(null);
  };
  return (
    <div className="container-fluid monitor_performance position-relative">
      <div className="d-flex spinner_center">
      <div className="d-flex align-items-center">
          <h2 className="heading_sm_center">Planogram Compliance</h2>
          <button
            onClick={() => emailImages("/api/images_download", "Images")}
            className="btn btn-primary"
            style={{ width: "120px", height: "32px", marginLeft: "5px" }}
            disabled={!yearFilters}
          >
            {buttonClicked && !csvData ? (
              <div
                className="spinner-border text-info"
                role="status"
                style={{ zIndex: "2", width: "20px", height: "20px" }}
              ></div>
            ) : (
              "Extract Images"
            )}
          </button>
        </div>

        <div className="d-flex align-items-center justify-content-center">
          {isLoading && (
            <div className="text-center">
              <div
                className="spinner-border text-info"
                role="status"
                style={{ zIndex: "2" }}
              ></div>
              <br />
              <span className="text-info d-block">Loading...</span>
            </div>
          )}
        </div>
      </div>
      <div className="media-body">
      {alertModal && (
          <Modal show={alertModal ? true : false} onHide={hideModal} centered>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
              {alertModal}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={hideModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        {alertError && <Alert variant="danger">{alertError}</Alert>}
        <div className="row content_main_row">
          <div className="filters col-md-12">
            <div className="row w-100 filters_row justify-content-between">
              <div className="col py-0">
                <div
                  className="filters_right w-100 justify-content-center"
                  style={{ gap: "5px" }}
                >
                  <div className="filter-dropdowns">
                    <Dropdown
                      className={`${
                        !selectedYear
                          ? "dropDown_disabled"
                          : "dropdown_selected"
                      }`}
                    >
                      <Dropdown.Toggle variant="secondary">
                        {selectedYear ? selectedYear : "Year"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {yearFilters?.map((year, index) => (
                          <Dropdown.Item
                            key={index}
                            onClick={(event) =>
                              setSelectedYear(event.target.text)
                            }
                          >
                            {year}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="filter-dropdowns">
                    <Dropdown
                      className={`${
                        !selectedMonth
                          ? "dropDown_disabled"
                          : "dropdown_selected"
                      }`}
                    >
                      <Dropdown.Toggle variant="secondary">
                        {selectedMonth ? selectedMonth : "Month"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {monthFilters?.map((month, index) => (
                          <Dropdown.Item
                            key={index}
                            onClick={(event) =>
                              setSelectedMonth(event.target.text)
                            }
                          >
                            {month}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <div className="col-md-9 col-xl-8">
                <div
                  className="filters_right w-100 justify-content-center"
                  style={{ gap: "5px" }}
                >
                  <div className="filter-dropdowns">
                    <Dropdown
                      className={`${
                        !(citiesToShow.length > 0)
                          ? "dropDown_disabled"
                          : "dropdown_selected"
                      }`}
                    >
                      <Dropdown.Toggle variant="secondary">
                        city
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div
                          className="p-3 d-flex flex-column"
                          style={{ gap: "5px" }}
                        >
                          <div className="d-flex" style={{ gap: "0 8px" }}>
                            <input
                              type="checkbox"
                              name="selectAll"
                              checked={citySelectedAll}
                              onChange={(e) => selectAll(e, "city")}
                            />
                            <label htmlFor="selectAll">Select All</label>
                          </div>

                          {cityFilters?.map((city, index) => (
                            <div
                              key={index}
                              className="d-flex"
                              style={{ gap: "0 10px" }}
                            >
                              <input
                                type="checkbox"
                                value={city}
                                onChange={(e) =>
                                  checkBoxFilterHandle(e, "city")
                                }
                                checked={
                                  citiesToShow.includes(city) ? true : false
                                }
                              />
                              <label style={{ fontSize: "12px" }}>{city}</label>
                            </div>
                          ))}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <div className="filter-dropdowns">
                    <Dropdown
                      className={`${
                        !(categoriesToShow.length > 0)
                          ? "dropDown_disabled"
                          : "dropdown_selected"
                      }`}
                    >
                      <Dropdown.Toggle variant="secondary">
                        category
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div
                          className="p-3 d-flex flex-column"
                          style={{ gap: "5px" }}
                        >
                          <div className="d-flex" style={{ gap: "0 8px" }}>
                            <input
                              type="checkbox"
                              name="selectAll"
                              checked={categorySelectedAll}
                              onChange={(e) => selectAll(e, "category")}
                            />
                            <label htmlFor="selectAll">Select All</label>
                          </div>

                          {categoryFilters?.map((category, index) => (
                            <div
                              key={index}
                              className="d-flex"
                              style={{ gap: "0 10px" }}
                            >
                              <input
                                type="checkbox"
                                value={category}
                                onChange={(e) =>
                                  checkBoxFilterHandle(e, "category")
                                }
                                checked={
                                  categoriesToShow.includes(category)
                                    ? true
                                    : false
                                }
                              />
                              <label style={{ fontSize: "12px" }}>
                                {category}
                              </label>
                            </div>
                          ))}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <div className="filter-dropdowns">
                    <Dropdown
                      className={`${
                        !(customersToShow.length > 0)
                          ? "dropDown_disabled"
                          : "dropdown_selected"
                      }`}
                    >
                      <Dropdown.Toggle variant="secondary">
                        customer
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div
                          className="p-3 d-flex flex-column"
                          style={{ gap: "5px" }}
                        >
                          <div className="d-flex" style={{ gap: "0 8px" }}>
                            <input
                              type="checkbox"
                              name="selectAll"
                              checked={customerSelectedAll}
                              onChange={(e) => selectAll(e, "customer")}
                            />
                            <label htmlFor="selectAll">Select All</label>
                          </div>

                          {customerFilters?.map((customer, index) => (
                            <div
                              key={index}
                              className="d-flex"
                              style={{ gap: "0 10px" }}
                            >
                              <input
                                type="checkbox"
                                value={customer}
                                onChange={(e) =>
                                  checkBoxFilterHandle(e, "customer")
                                }
                                checked={
                                  customersToShow.includes(customer)
                                    ? true
                                    : false
                                }
                              />
                              <label style={{ fontSize: "12px" }}>
                                {customer}
                              </label>
                            </div>
                          ))}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <div className="filter-dropdowns">
                    <Dropdown
                      className={`${
                        !(regionsToShow.length > 0)
                          ? "dropDown_disabled"
                          : "dropdown_selected"
                      }`}
                    >
                      <Dropdown.Toggle variant="secondary">
                        region
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div
                          className="p-3 d-flex flex-column"
                          style={{ gap: "5px" }}
                        >
                          <div className="d-flex" style={{ gap: "0 8px" }}>
                            <input
                              type="checkbox"
                              name="selectAll"
                              checked={regionSelectedAll}
                              onChange={(e) => selectAll(e, "region")}
                            />
                            <label htmlFor="selectAll">Select All</label>
                          </div>

                          {regionFilters?.map((region, index) => (
                            <div
                              key={index}
                              className="d-flex"
                              style={{ gap: "0 10px" }}
                            >
                              <input
                                type="checkbox"
                                value={region}
                                onChange={(e) =>
                                  checkBoxFilterHandle(e, "region")
                                }
                                checked={
                                  regionsToShow.includes(region) ? true : false
                                }
                              />
                              <label style={{ fontSize: "12px" }}>
                                {region}
                              </label>
                            </div>
                          ))}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <button className="btn btn-success" onClick={applyfilters}>
                    Apply Filters
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="row justify-content-center"
          style={{ marginTop: "5px" }}
        >
          <div className="col-12 d-flex justify-content-center">
            <div className="card">
              <div className="card-content">
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <div className="align-self-center d_sm_none">
                      <BsBookshelf className="icon" />
                    </div>
                    <div className="media-body text-right total_visits_con">
                      <span>Planogram Percentage</span>
                      <h3 style={{ marginTop: "10px" }}>
                        {!availabilityPercentage?.result
                          ? "No Data Found"
                          : isNaN(availabilityPercentage?.result)
                          ? availabilityPercentage?.result
                          : availabilityPercentage?.result + "%"}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row gap-3 justify-content-center">
        <div className="col-md-5">
          <div className="row w-100 flex-column align-items-center">
            <div className="col-12 my_graph">
              <DoubleBarChart
                dataset={categoryDataset}
                label={"Planogram By Category (%)"}
                loading={categoryDataset ? false : true}
              />
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <div className="row w-100 flex-column align-items-center">
            <div className="col-12 my_graph">
              <DoubleBarChart
                dataset={cityDataset}
                label={"Planogram By City (%)"}
                loading={cityDataset ? false : true}
              />
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <div className="row w-100 flex-column align-items-center">
            <div className="col-12 my_graph">
              <DoubleBarChart
                dataset={customerDataset}
                label={"Planogram By Customer (%)"}
                loading={customerDataset ? false : true}
              />
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <div className="row w-100 flex-column align-items-center">
            <div className="col-12 my_graph">
              <DoubleBarChart
                dataset={monthDataset}
                label={"Planogram By Month (%)"}
                loading={monthDataset ? false : true}
              />
            </div>
          </div>
        </div>
      </div>
      <section className="card table table-responsive">
        <label htmlFor="" className="table_label mb-3">
          Planogram By Category By Customer (%)
        </label>
        <Table bordered className="twoD_table">
          <thead>
            <tr>
              <th>Category</th>
              {tableData?.customer?.map((item) => (
                <th
                  style={{ backgroundColor: "#B95537", color: "#ffffff" }}
                  scope="col"
                >
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData?.category?.map((cat, index) => (
              <tr className="table-primary">
                <th
                  style={{ backgroundColor: "#B95537", color: "#ffffff" }}
                  scope="row"
                >
                  {cat}
                </th>
                {tableData?.customer.map((cus) => (
                  <>
                    {tableData?.data?.map((item) => {
                      if (
                        item?.categoryShortName === cat &&
                        item?.StoreCustomerName === cus
                      ) {
                        return <td>{item?.result}</td>;
                      }
                    })}
                  </>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </section>

      {/* <section className='card'>
                <label htmlFor="" className='table_label'>Planogram By Wave</label>
                {(planogramWaveRows?.length > 0) &&
                    <DynamicDataTable columnDetails={planogramWaveColumnDetails}
                        rowID={'ID'}
                        columnHeaders={planogramWaveColumnHeaders}
                        rows={planogramWaveRows}
                        isLoading={planogramWaveTableLoading}
                        setPageSize={setPlanogramWavePageSize}
                        pageSize={planogramWavePageSize}
                        setPage={setPlanogramWavePage}
                        page={planogramWavePage}
                        rowCount={planogramWaveRowCount}
                    />
                }
            </section> */}
      <CustomDataMap regionData={regionData} />
    </div>
  );
};

export default Planogram;
