import { BrowserRouter } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import RouterLinks from './routes/RouterLinks';
import { NotificationProvider } from './components/NotificationProvider';

function App({ msalInstance }) {
  return (
    <MsalProvider instance={msalInstance}>
      <NotificationProvider> {/* Wrap with NotificationProvider */}
        <BrowserRouter>
          <RouterLinks />
        </BrowserRouter>
      </NotificationProvider>
    </MsalProvider>
  );
}

export default App;
