import { useState, useEffect, memo, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Link, Navigate, useLocation } from 'react-router-dom';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import CustomMultiselect from '../../components/CustomMultiselect';
import moment from 'moment';
import dayjs from 'dayjs';
import axios from 'axios';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-lazy-load-image-component/src/effects/blur.css'; // Import the blur effect CSS
import 'react-dropdown/style.css';
import '../../styles/PhotoDetails.css';
import '../../styles/general.css';

const styles = () => (
{
  root: {
    position: 'relative',
    top: '20px',
    left: '10px',
    width: 'fit-content'
  },
  gridList: {
    width: '100',
    height: '68vh'
  },
  gridListTile: {
    border: '2.55px solid var(--customPrimary)',
    borderRadius: '20px',
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  }
});

const Photos = (props) => 
{
  const { classes, currentUser } = props;
  const location = useLocation();
  const data = location?.state || null;

  // API VARIABLES
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  // DATE FILTERS VARIABLES
  const [selectedStartDate, setSelectedStartDate] = useState(dayjs().startOf('month'));
  const [selectedEndDate, setSelectedEndDate] = useState(dayjs());
  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);

  // COUNTRY FILTER VARIABLES
  const [countryList, setCountryList] = useState([]);
  const [countriesToShow, setCountriesToShow] = useState([]);
  const [countrySelectedAll, setCountrySelectedAll] = useState(false);

  // CHANNEL FILTER VARIABLES
  // const [channelList, setChannelList] = useState([]);
  // const [channelsToShow, setChannelsToShow] = useState([]);
  // const [channelSelectedAll, setChannelSelectedAll] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState("");

  // CITY FILTER VARIABLES
  const [cityList, setCityList] = useState([]);
  const [citiesToShow, setCitiesToShow] = useState([]);
  const [citySelectedAll, setCitySelectedAll] = useState(false);

  // AREA FILTER VARIABLES
  // const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const [categoriesToShow, setCategoriesToShow] = useState([]);
  const [categorySelectedAll, setCategorySelectedAll] = useState(false);

  // STORE FILTER VARIABLES
  const [storeList, setStoreList] = useState([]);
  const [storesToShow, setStoresToShow] = useState([]);
  const [storeSelectedAll, setStoreSelectedAll] = useState(false);

  // IMAGE VARIABLES
  const [imageData, setImageData] = useState([]);
  const [filteredImageData, setFilteredImageData] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(-1);
  const imageRefs = useRef([]);

  // FILTER VARIABLES
  const [filtered, setFiltered] = useState(false);
  
  // PAGINATION VARIABLES
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => 
  {
    const getFilterValues = async () => 
    {
      await axios({
        method: "post",
        url: "/api/filter_values_on_shelf",
      })
      .then((response) => 
      {
        if (response?.request?.status === 200) 
        {
          setCountryList(response?.data?.country);
          setCityList(response?.data?.city);
          setCategoryList(response?.data?.category);
        }
      })
      .catch((error) => 
      {
        console.log("Filter Arrays Api: ", error);
        setIsLoading(false);
        setErrorMessage("There is a problem in the server. Please contact site admin.");
      });
    }

    const fetchImages = async () => 
    {
      try 
      {
        const response = await fetch("/api/filteredBrandImages", 
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            country: countriesToShow,
            city: citiesToShow,
            category: categoriesToShow,
            channel: selectedChannel,
            store: storesToShow,
            startDate: moment(selectedStartDate?.$d).startOf("month").format("YYYY-MM-DD"),
            endDate: moment(selectedEndDate?.$d).format("YYYY-MM-DD"),
            page: 0,
            limit: 100
          })
        });

        const data = await response.json();

        setFilteredImageData(data.images);
        setTotalPages(Math.ceil(data?.length / 100));
        setPage(0);
        setFiltered(true);
      } 
      catch (error) 
      {
        console.log("Filtered Rejected Images Api Error: ", error);
        setErrorMessage("Internal Server Error! Please try again later.");
      }

      setIsLoading(false);
    }

    console.log(data);
    if (!data)
    {
      getFilterValues();
      fetchImages();
    }
    else
    {
      setIsLoading(true);
      const { startDate, endDate, country, city, category, store, images, imageIndex, page, totalPages } = data;

      setSelectedStartDate(dayjs(startDate));
      setSelectedEndDate(dayjs(endDate));
      setCountriesToShow(country);
      setCitiesToShow(city);
      setCategoriesToShow(category);
      setStoresToShow(store);
      setFilteredImageData(images);
      setSelectedImageIndex(imageIndex);
      setPage(page);
      setTotalPages(totalPages);
      setIsLoading(false);
    }

    window.history.replaceState({}, '');
  }, [data]);

  useEffect(() =>
  {
    // Populate refs when images are updated
    imageRefs.current = imageRefs.current.slice(0, filteredImageData.length);

    // Scroll to selected image
    if (imageRefs?.current[selectedImageIndex]) 
    {
      imageRefs.current[selectedImageIndex]?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [filteredImageData, selectedImageIndex]);

  useEffect(() => 
  {
    const getStoreFilters = async () => 
    {
      await axios({
        method: "post",
        url: "/api/get_stores",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify({
          city: citiesToShow,
          category: categoriesToShow,
          startDate: moment(selectedStartDate?.$d).format("YYYY-MM-DD"),
          endDate: moment(selectedEndDate?.$d).format("YYYY-MM-DD")
        })
      })
      .then((response) => 
      {
        if (response?.request?.status === 200) 
        {
          setStoreList(response?.data?.store_name);
        }
      })
      .catch((error) => 
      {
        console.log("Filter Arrays Api: ", error);
        setIsLoading(false);
        setErrorMessage("There is a problem in the server. Please contact site admin.");
      });
    }

    getStoreFilters();
  }, [citiesToShow, categoriesToShow, selectedStartDate, selectedEndDate]);

  /* const updateStartDate = (date) => 
  {
    setStartDate(date);
  }

  const updateEndDate = (date) => 
  {
    setEndDate(date);
  }

  const updateCountry = (e) => 
  {
    setSelectedCountry(e.target.value);
  }

  const updateCity = (e) => 
  {
    setSelectedCity(e.target.value);
  }

  const updateChannel = (e) => 
  {
    setSelectedChannel(e.target.value);
  }

  const updateStore = (e) => 
  {
    setSelectedStore(e.target.value);
  }

  const updateCategory = (e) => 
  {
    setSelectedCategory(e.target.value);
  }

  const handleReset = () => 
  {
    setFilteredImageData(imageData);
    setFiltered(false);
    setSelectedStartDate(new Date());
    setSelectedEndDate(new Date());
    setSelectedCountry("");
    setSelectedCity("");
    setSelectedChannel("");
    setSelectedStore("");
    setSelectedCategory("");
    setPage(0);
    setTotalPages(1);
  }*/

  const handleFilter = async () => 
  {
    setIsLoading(true);
  
    try 
    {
      const response = await fetch("/api/filteredBrandImages", 
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          country: countriesToShow,
          city: citiesToShow,
          category: categoriesToShow,
          channel: selectedChannel,
          store: storesToShow,
          startDate: moment(selectedStartDate?.$d).format("YYYY-MM-DD"),
          endDate: moment(selectedEndDate?.$d).format("YYYY-MM-DD"),
          page: 0,
          limit: 100
        })
      });

      const data = await response.json();

      setFilteredImageData(data.images);
      setTotalPages(Math.ceil(data?.length / 100));
      setPage(0);
      setFiltered(true);
    } 
    catch (error) 
    {
      console.log("Filtered Rejected Images Api Error: ", error);
      setErrorMessage("Internal Server Error! Please try again later.");
    }

    setIsLoading(false);
  }

  const handlePageChange = async (event, value) => 
  {
    setIsLoading(true);
  
    try 
    {
      const response = await fetch("/api/filteredBrandImages", 
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          country: countriesToShow,
          city: citiesToShow,
          category: categoriesToShow,
          channel: selectedChannel,
          store: storesToShow,
          startDate: moment(selectedStartDate?.$d).format("YYYY-MM-DD"),
          endDate: moment(selectedEndDate?.$d).format("YYYY-MM-DD"),
          page: value - 1,
          limit: 100
        })
      });

      const data = await response.json();

      setImageData(data.images);
      setFilteredImageData(data.images);
      setTotalPages(Math.ceil(data?.length / 100));
      setPage(value - 1);
    } 
    catch (error) 
    {
      console.log("Rejected Images Api Error: ", error);
      setErrorMessage("Internal Server Error! Please try again later.");
    }

    setIsLoading(false);
  }

  const handleSelectStartDate = (newStartDate) => 
  {
    setSelectedStartDate(newStartDate);

    if (newStartDate && !selectedEndDate) 
    {
      setStartDateError(false);
      setEndDateError(true);
    } 
    else if (!newStartDate && selectedEndDate) 
    {
      setStartDateError(true);
      setEndDateError(false);
    } 
    else
    {
      setStartDateError(false);
      setEndDateError(false);
    }
  }

  const handleSelectEndDate = (newEndDate) => 
  {
    setSelectedEndDate(newEndDate);

    if (!selectedStartDate && newEndDate) 
    {
      setStartDateError(true);
      setEndDateError(false);
    } 
    else if (selectedStartDate && !newEndDate) 
    {
      setStartDateError(false);
      setEndDateError(true);
    } 
    else 
    {
      setStartDateError(false);
      setEndDateError(false);
    }
  }

  return (
    <div className = "container-fluid main_container">
      {!currentUser?.user ? (
        <Navigate to = "/login" replace = {true} />
      ) : (
        <div>
          <div className = "row w-100 filters_row justify-content-between">
            <div className = "col py-0">
              <div
                className = "filters_right w-100 justify-content-center"
                style = {{ gap: '5px', gridTemplateColumns: '1fr 1fr' }}
              >
                <LocalizationProvider dateAdapter = {AdapterDayjs}>
                  <div style = {{ width: '140px' }}>
                    <DatePicker
                      className = {`custom_date_picker ${selectedStartDate && "date_selected"}`}
                      label = "From"
                      inputFormat = "DD-MMM-YYYY"
                      mask = {"__-__-____ __"}
                      value = {selectedStartDate}
                      maxDate = {selectedEndDate}
                      disableFuture = {true}
                      onChange = {handleSelectStartDate}
                      renderInput = {(params) => (
                        <TextField
                          {...params}
                          error = {startDateError}
                          helperText = {null}
                          size = "small"
                        />
                      )}
                    />
                  </div>

                  <div style = {{ width: '140px' }}>
                    <DatePicker
                      className = {`custom_date_picker ${selectedEndDate && "date_selected"}`}
                      label = "To"
                      inputFormat = "DD-MMM-YYYY"
                      mask = {"__-__-____ __"}
                      value = {selectedEndDate}
                      minDate = {selectedStartDate}
                      disableFuture = {true}
                      onChange = {handleSelectEndDate}
                      renderInput = {(params) => (
                        <TextField
                          {...params}
                          error = {endDateError}
                          helperText = {null}
                          size = "small"
                        />
                      )}
                    />
                  </div>
                </LocalizationProvider>
              </div>
            </div>

            <div className = "col-md-9 col-xl-8">
              <div
                className = "filters_right w-100 justify-content-center"
                style = {{ gap: '5px' }}
              >
                <CustomMultiselect
                  filterValues = {countryList}
                  isSelectedAll = {countrySelectedAll}
                  label = "Country"
                  setValuesToShow = {setCountriesToShow}
                  setSelectedAll = {setCountrySelectedAll}
                  valuesToShow = {countriesToShow ? countriesToShow : []}
                />

                <CustomMultiselect
                  filterValues = {cityList}
                  isSelectedAll = {citySelectedAll}
                  label = "City"
                  setValuesToShow = {setCitiesToShow}
                  setSelectedAll = {setCitySelectedAll}
                  valuesToShow = {citiesToShow ? citiesToShow : []}
                />

                <CustomMultiselect
                  filterValues = {categoryList}
                  isSelectedAll = {categorySelectedAll}
                  label = "Category"
                  setValuesToShow = {setCategoriesToShow}
                  setSelectedAll = {setCategorySelectedAll}
                  valuesToShow = {categoriesToShow ? categoriesToShow : []}
                />

                <CustomMultiselect
                  filterValues = {storeList}
                  isSelectedAll = {storeSelectedAll}
                  label = "Store"
                  setValuesToShow = {setStoresToShow}
                  setSelectedAll = {setStoreSelectedAll}
                  valuesToShow = {storesToShow ? storesToShow : []}
                />

                <div className = "btn-group" role = "group" aria-label = "Basic example">
                  <button
                    className = "apply_filter"
                    onClick = {handleFilter}
                    disabled = {isLoading}
                  >
                    Filter
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className = "photoHubDataContainer">
            <div style = {{ width: '100%' }}>
              {isLoading ? (
                <div className = "d-flex justify-content-center loader_con">
                  <br />
                  <br />
                  <div className = "spinner-border text-info" role = "status"></div>
                </div>
              ) : errorMessage ? (
                <span>
                  <br />
                  {errorMessage}
                </span>
              ) : filteredImageData.length === 0 ? (
                <span>
                  <br />
                  No pictures to display.
                </span>
              ) : (
                filteredImageData.length > 0 && (
                  <div className = {classes.root}>
                    <ImageList
                      cols = {6}
                      rowHeight = {110}
                      gap = {20}
                      className = {classes.gridList}
                    >
                      {filteredImageData.map((image, index) => (
                        <ImageListItem 
                          ref = {(element) => { imageRefs.current[index] = element; }}
                          key = {image.filestoragePath + index}
                        >
                          <Link
                            to = {`/photoHub/photo-details/${index}`}
                            state = {{
                              images: filteredImageData,
                              imageIndex: index,
                              page: page,
                              totalPages: totalPages,
                              country: countriesToShow,
                              category: categoriesToShow,
                              city: citiesToShow,
                              channel: selectedChannel,
                              store: storesToShow,
                              startDate: moment(selectedStartDate?.$d).format("YYYY-MM-DD"),
                              endDate: moment(selectedEndDate?.$d).format("YYYY-MM-DD")
                            }}
                          >
                            <LazyLoadImage
                              alt =""
                              src = {`https://storage.googleapis.com/staging-api-uploads/${image.filestoragePath}`} // Image URL
                              effect = "blur" // Progressive loading effect
                              className = {classes.gridListTile}
                            />
                          </Link>
                        </ImageListItem>
                      ))}
                    </ImageList>

                    <Stack spacing = {2} style = {{ marginTop: '1em' }}>
                      <div
                        style = {{
                          display: 'flex',
                          alignItems: 'center',
                          columnGap: '10px',
                          justifyContent: 'center'
                        }}
                      >
                        <Typography>Page: {page + 1}</Typography>
                        <Pagination
                          count = {totalPages}
                          page = {page + 1}
                          siblingCount = {0}
                          onChange = {handlePageChange}
                        />
                      </div>
                    </Stack>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default withStyles(styles)(memo(Photos));